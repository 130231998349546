<script setup lang="ts">
import AdChannelIcon from "@/app/ad-channel-icon.vue";
import Demo from "@/app/landing/demo.vue";
import Step from "@/app/landing/step.vue";
import BudgetChangeApprovalDialog from "@/app/workspace/approval/budget-change-approval-dialog.vue";
import CreateBudgetDialog from "@/app/workspace/campaigns/shared-budgets/create-shared-budget-dialog.vue";
import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import LoadingBar from "@/components/loading-bar.vue";
import { AdChannelType } from "@/enums/ad-channel-type.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
</script>
<template>
  <div
    class="flex w-auto flex-col space-y-20 rounded-xl lg:space-y-28"
    id="how-it-works"
  >
    <step>
      <template #subtitle> Step 1 </template>
      <template #title> Connect ad channels </template>
      <!--      <template #description>-->
      <!--        <span class="text-balance"-->
      <!--          >Our one-click integrations couldn't be any simpler</span-->
      <!--        >-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box">
          <dialog-titlebar
            title="Select an ad channel to connect"
          ></dialog-titlebar>
          <dialog-body>
            <div class="mb-1 grid grid-cols-3 gap-x-7 gap-y-6">
              <div class="flex items-center justify-center">
                <div class="relative flex items-center justify-center">
                  <ad-channel-icon
                    :type="AdChannelType.META"
                    class="h-24 w-24 cursor-pointer transition-all"
                  />
                </div>
              </div>
              <div class="flex items-center justify-center">
                <div class="relative flex items-center justify-center">
                  <ad-channel-icon
                    :type="AdChannelType.GOOGLE"
                    class="h-24 w-24 cursor-pointer transition-all"
                  />
                </div>
              </div>
            </div>
          </dialog-body>
        </div>
      </template>
    </step>
    <step>
      <template #subtitle> Step 2 </template>
      <template #title> Create a shared budget </template>
      <!--      <template #description>-->
      <!--        Choose an amount and a target metric for the algorithm to control-->
      <!--        budgets within this group to optimise-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box" v-if="workspaceStore.workspace">
          <create-budget-dialog
            title="Create shared budget"
          ></create-budget-dialog>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
    <step>
      <template #subtitle> Step 3 </template>
      <template #title> Assign campaigns to shared budget </template>
      <!--      <template #description>-->
      <!--        Choose an amount and a target metric for the algorithm to control-->
      <!--        budgets within this group to optimise-->
      <!--      </template>-->
      <template #image>
        <div
          class="dialog-box tall w-full max-w-[1200px]"
          v-if="workspaceStore.workspace"
        >
          <demo></demo>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
    <step>
      <template #subtitle> Step 4 </template>
      <template #title> Receive budget optimisations </template>
      <!--      <template #description>-->
      <!--        Or allow Charter to run fully automated-->
      <!--      </template>-->
      <template #image>
        <div class="dialog-box" v-if="workspaceStore.workspace">
          <budget-change-approval-dialog
            :run="workspaceStore.workspace.shared_budgets[0].runs[0]"
          ></budget-change-approval-dialog>
        </div>
        <loading-bar class="w-3.5" v-else></loading-bar>
      </template>
    </step>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";

import { Campaign } from "@/dtos/campaign.ts";
import { useCurrencyStore } from "@/stores/currency.ts";

const currencyStore = useCurrencyStore();

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
</script>
<template>
  <div
    class="flex flex-col items-start font-sans"
    v-if="props.campaign.lifetime_budget && props.campaign.budget_remaining"
  >
    <span class="font-mono text-sm"
      >{{
        currencyStore.format(
          props.campaign.budget_remaining,
          props.campaign.currency,
        )
      }}
      /
      {{
        currencyStore.format(
          props.campaign.lifetime_budget,
          props.campaign.currency,
        )
      }}</span
    >
    <span class="text-xs">Remaining budget</span>
  </div>
  <div class="flex flex-col items-start" v-else>
    <span class="font-sans text-xs text-purple-300/70">Monthly budget</span>
    <span class="font-mono text-sm text-purple-300">{{
      currencyStore.format(
        props.campaign.effective_daily_budget * (365.25 / 12),
        props.campaign.currency,
      )
    }}</span>
  </div>
</template>

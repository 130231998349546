<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import shared_budget from "@/assets/docs/shared-budget.png";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Charter’s shared budgets allow you to organise campaigns by different
      objectives, and use Charter’s algorithm to work towards those objectives.
      Each shared budget has its own maximum budget that Charter works within,
      and which you can adjust at any time.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        Let's say you have 2 shared budgets, one for brand awareness where you
        are wanting to optimise for Reach, and another for sales where you are
        wanting to optimise for Revenue. You set the Reach budget to
        $2,000/month and the Revenue budget to $1,000/month.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>
      Shared budgets can include campaigns from different ad channels - this is
      a key part of Charter’s magic.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        Within your Reach shared budget you have 2 Meta campaigns and 1 Google
        campaign. Charter will continuously monitor how each of these campaigns
        are performing with the goal of maximising Reach, and adjust the portion
        of shared budget each campaign receives.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-4 pr-4">
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="shared_budget" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import budget_change_automatic from "@/assets/docs/budget-change-automatic.png";
import budget_change_manual from "@/assets/docs/budget-change-manual.png";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Charter's algorithm can run either automatically or manually.
    </p>
    <p>
      Automatically - completely hands off, it’ll make the budget changes
      without you needing to do anything, and you can review changes in the
      algorithm run log.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_change_automatic" />
    </div>
    <p>
      Manually - the algorithm will run as in automatically, but it won’t make
      the budget changes without your approval. You will automatically get email
      notifications for budget approval requests.
    </p>
    <p>You can set budget change approval within each shared budget.</p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_change_manual" />
    </div>
    <p>
      When there’s a budget change to approve, you’ll get a email notification
      which you can click directly through to approve. Or, within your
      dashboard, you can simply click the notification within the shared budget
      and approve from there.
    </p>
  </div>
</template>

<script setup lang="ts">
import {
  CaretDownIcon,
  CaretRightIcon,
  GearIcon,
  LightningBoltIcon,
} from "@radix-icons/vue";
import { computed, PropType, ref } from "vue";

import BudgetChangeApprovalDialog from "@/app/workspace/approval/budget-change-approval-dialog.vue";
import Graph, {
  DataPoint,
  DataSeries,
} from "@/app/workspace/campaigns/graph.vue";
import CreateBudgetDialog from "@/app/workspace/campaigns/shared-budgets/create-shared-budget-dialog.vue";
import RemoveBudgetDialog from "@/app/workspace/campaigns/shared-budgets/remove-shared-budget-dialog.vue";
import DialogWrapper from "@/components/dialog-wrapper.vue";
import { BudgetingAlgorithmRun } from "@/dtos/budgeting-algorithm-run.ts";
import { GroupedCampaigns } from "@/dtos/grouped-campaigns.ts";
import { SharedBudgetMetrics } from "@/dtos/shared-budget-metrics.ts";
import { BudgetingAlgorithmRunStatus } from "@/enums/budgeting-algorithm-run-status.ts";
import { TargetMetric } from "@/enums/target-metric.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const currencyStore = useCurrencyStore();
const workspaceStore = useWorkspaceStore();

const emit = defineEmits<{
  (e: "open"): void;
  (e: "close"): void;
}>();
const props = defineProps({
  groupOfCampaigns: {
    type: Object as PropType<GroupedCampaigns>,
    required: true,
  },
  open: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const approval_open = ref<boolean>(false);
const manage_open = ref<boolean>(false);
const remove_open = ref<boolean>(false);

const pending_budgeting_algorithm_run = computed<
  BudgetingAlgorithmRun | undefined
>(() => {
  if (!props.groupOfCampaigns.group) {
    return undefined;
  }
  for (let ii = 0; ii < props.groupOfCampaigns.group.runs.length; ii++) {
    if (
      props.groupOfCampaigns.group.runs[ii].status ===
      BudgetingAlgorithmRunStatus.PENDING
    ) {
      return props.groupOfCampaigns.group.runs[ii];
    }
  }
  return undefined;
});

const toggleOpen = () => {
  if (props.open) {
    emit("close");
  } else {
    emit("open");
  }
};

const effectiveDailyBudgetData = computed(() => {
  const series: DataSeries[] = [];

  if (!workspaceStore.workspace || !props.groupOfCampaigns.group) {
    return series;
  }
  const budget_line_color = "rgb(121,99,149)";
  const budget_text_color = "rgba(216, 180, 254, 1)";

  const max_y_axis = Math.ceil(
    Math.max(
      ...props.groupOfCampaigns.group.time_series.map(
        (item: SharedBudgetMetrics) => item.effective_daily_budget,
      ),
    ),
  );
  const budget_values = props.groupOfCampaigns.group.time_series.map((item) => {
    const item_time = item.time;
    let date = new Date(item_time);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date = new Date(date.getTime() - 43200000);
    return {
      date: date,
      value: item.effective_daily_budget,
    } as DataPoint;
  });

  // budget_values.push({
  //   date: new Date(new Date().setHours(23, 59, 59, 999)),
  //   value: props.groupOfCampaigns.group.effective_daily_budget, // todo: adjust for proportion of the forecast day until now?
  //   label_override: "Daily budget",
  // } as DataPoint);

  series.push({
    label: "Spend",
    positive_line_color: budget_line_color,
    positive_text_color: budget_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_y_axis,
    values: budget_values,
    valueFormatter: (value: number) =>
      currencyStore.format(value, workspaceStore.workspace!.currency),
  });

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1);
});
const utilizationData = computed(() => {
  const series: DataSeries[] = [];

  if (!workspaceStore.workspace || !props.groupOfCampaigns.group) {
    return series;
  }
  const budget_line_color = "rgb(121,99,149)";
  const budget_text_color = "rgba(216, 180, 254, 1)";

  const max_y_axis = Math.ceil(
    Math.max(
      ...props.groupOfCampaigns.group.time_series.map(
        (item: SharedBudgetMetrics) => item.utilization,
      ),
      1,
    ),
  );
  const budget_values = props.groupOfCampaigns.group.time_series.map((item) => {
    const item_time = item.time;
    let date = new Date(item_time);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date = new Date(date.getTime() - 43200000);
    return {
      date: date,
      value: item.utilization,
    } as DataPoint;
  });

  // budget_values.push({
  //   date: new Date(new Date().setHours(23, 59, 59, 999)),
  //   value: props.groupOfCampaigns.group.utilization, // todo: adjust for proportion of the forecast day until now?
  // } as DataPoint);

  series.push({
    label: "Utilization",
    positive_line_color: budget_line_color,
    positive_text_color: budget_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_y_axis,
    values: budget_values,
    valueFormatter: (value: number) => `${formatNumber(value * 100, 2)}%`,
  });

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1);
});

const roasData = computed(() => {
  const series: DataSeries[] = [];

  if (!workspaceStore.workspace || !props.groupOfCampaigns.group) {
    return series;
  }
  const positive_metric_line_color = "#0faa77";
  const positive_metric_text_color = "#00ffac";

  const max_y_axis = Math.ceil(
    Math.max(
      ...props.groupOfCampaigns.group.time_series
        .filter((item: SharedBudgetMetrics) => !!item.roas_in_previous_week)
        .map((item: SharedBudgetMetrics) => item.roas_in_previous_week!),
    ),
  );
  const values = props.groupOfCampaigns.group.time_series.map((item) => {
    const item_time = item.time;
    let date = new Date(item_time);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date = new Date(date.getTime() - 43200000);
    return {
      date: date,
      value: item.roas_in_previous_week,
    } as DataPoint;
  });

  // values.push({
  //   date: new Date(new Date().setHours(23, 59, 59, 999)),
  //   value: props.groupOfCampaigns.group.roas_in_previous_week, // todo: adjust for proportion of the forecast day until now?
  // } as DataPoint);

  series.push({
    label: "Return on ad spend",
    positive_line_color: positive_metric_line_color,
    positive_text_color: positive_metric_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_y_axis,
    values: values,
    valueFormatter: (value: number) => formatNumber(value, 2),
  });

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1);
});
const adProfitData = computed(() => {
  const series: DataSeries[] = [];

  if (!workspaceStore.workspace || !props.groupOfCampaigns.group) {
    return series;
  }
  const positive_metric_line_color = "#0faa77";
  const positive_metric_text_color = "#00ffac";

  const max_y_axis = Math.ceil(
    Math.max(
      ...props.groupOfCampaigns.group.time_series
        .filter((item: SharedBudgetMetrics) => !!item.daily_ad_profit_forecast)
        .map((item: SharedBudgetMetrics) => item.daily_ad_profit_forecast!),
    ),
  );
  const values = props.groupOfCampaigns.group.time_series.map((item) => {
    const item_time = item.time;
    let date = new Date(item_time);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date = new Date(date.getTime() - 43200000);
    return {
      date: date,
      value: item.daily_ad_profit_forecast,
    } as DataPoint;
  });

  // values.push({
  //   date: new Date(new Date().setHours(23, 59, 59, 999)),
  //   value: props.groupOfCampaigns.group.daily_ad_profit_forecast, // todo: adjust for proportion of the forecast day until now?
  // } as DataPoint);

  series.push({
    label: "Ad profit",
    positive_line_color: positive_metric_line_color,
    positive_text_color: positive_metric_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_y_axis,
    values: values,
    valueFormatter: (value: number) =>
      currencyStore.format(value, workspaceStore.workspace!.currency),
  });

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1);
});
</script>
<template>
  <div
    class="flex h-24 items-center justify-between border-white/12 bg-gradient-to-b from-white/5 to-white/0 pl-4 pr-9"
    v-if="workspaceStore.workspace"
  >
    <div class="flex items-center justify-start space-x-4">
      <div
        class="group flex cursor-pointer items-center space-x-4 rounded-full p-5"
        @click="toggleOpen"
      >
        <CaretDownIcon
          v-if="props.open"
          class="h-auto w-7 opacity-30 transition-all duration-75 group-hover:opacity-100"
        ></CaretDownIcon>
        <CaretRightIcon
          v-else
          class="h-auto w-7 opacity-30 transition-all duration-75 group-hover:opacity-100"
        ></CaretRightIcon>
        <div
          class="flex items-center space-x-2 font-medium"
          v-if="props.groupOfCampaigns.group"
        >
          <span>{{
            props.groupOfCampaigns.group?.name ?? "Shared budget"
          }}</span>
          <span class="opacity-30">
            ({{ props.groupOfCampaigns.campaigns.length }})</span
          >
        </div>
        <div
          class="flex items-center space-x-2 font-medium"
          v-else-if="!props.groupOfCampaigns.group"
        >
          <span>Unassigned campaigns</span>
          <span class="opacity-30">
            ({{ props.groupOfCampaigns.campaigns.length }})</span
          >
        </div>
      </div>
      <div
        class="hidden items-center justify-start space-x-8 sm:flex"
        v-if="props.groupOfCampaigns.group"
      >
        <div class="flex items-center space-x-3">
          <div class="hidden h-[38px] w-[38px] min-[1400px]:block">
            <graph
              v-if="workspaceStore.campaigns_timeframe"
              :timeframe="workspaceStore.campaigns_timeframe"
              :grid="false"
              :fill="true"
              :data="effectiveDailyBudgetData"
              :currency="workspaceStore.workspace.currency"
              :uuid="`shared-budget-amount-${props.groupOfCampaigns.group.id}`"
            ></graph>
          </div>
          <div class="flex flex-col">
            <span class="text-sm font-light text-purple-300/70"
              >Monthly spend</span
            >
            <div class="font-mono text-base font-semibold text-purple-300/75">
              <span>{{
                currencyStore.format(
                  props.groupOfCampaigns.group.effective_daily_budget
                    ? props.groupOfCampaigns.group.effective_daily_budget *
                        (365.25 / 12)
                    : 0,
                  workspaceStore.workspace.currency,
                )
              }}</span>
              <span class="hidden opacity-50 lg:inline">{{ " / " }}</span>
              <span class="hidden opacity-50 lg:inline">{{
                currencyStore.format(
                  props.groupOfCampaigns.group.monthly_budget_ceiling,
                  workspaceStore.workspace.currency,
                )
              }}</span>
            </div>
          </div>
        </div>

        <div class="hidden items-center space-x-4 min-[1200px]:flex">
          <div class="hidden h-[38px] w-[38px] min-[1400px]:block">
            <graph
              v-if="workspaceStore.campaigns_timeframe"
              :timeframe="workspaceStore.campaigns_timeframe"
              :grid="false"
              :fill="true"
              :data="utilizationData"
              :currency="workspaceStore.workspace.currency"
              :uuid="`shared-budget-utilization-${props.groupOfCampaigns.group.id}`"
            ></graph>
          </div>
          <div class="flex flex-col">
            <span class="text-sm font-light text-purple-300/70"
              >Utilization</span
            >
            <div class="font-mono text-base font-semibold text-purple-300/75">
              {{
                ((props.groupOfCampaigns.group.utilization ?? 0) * 100).toFixed(
                  2,
                )
              }}%
            </div>
          </div>
        </div>
        <!--        <div class="flex flex-col opacity-50">-->
        <!--          <span class="text-sm font-light text-purple-300/70"-->
        <!--            >Target metric</span-->
        <!--          >-->
        <!--          <div class="font-mono text-base font-semibold text-purple-300/75">-->
        <!--            {{ targetMetricName(props.groupOfCampaigns.group.target_metric) }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div
      class="flex items-center justify-end space-x-7"
      v-if="props.groupOfCampaigns.group"
    >
      <div
        class="hidden items-center space-x-8 sm:flex"
        v-if="workspaceStore.workspace"
      >
        <div
          class="flex items-center space-x-4"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.AD_PROFIT ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.REVENUE ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.ROAS) &&
            props.groupOfCampaigns.group.roas_in_previous_week !== undefined
          "
        >
          <div class="hidden h-[38px] w-[38px] min-[1400px]:block">
            <graph
              v-if="workspaceStore.campaigns_timeframe"
              :timeframe="workspaceStore.campaigns_timeframe"
              :grid="false"
              :fill="true"
              :data="roasData"
              :currency="workspaceStore.workspace.currency"
              :uuid="`shared-budget-roas-${props.groupOfCampaigns.group.id}`"
            ></graph>
          </div>
          <div class="items-left flex flex-col">
            <span class="font-sans text-sm text-emerald-300/70">ROAS</span>
            <span class="font-mono text-base text-emerald-300">{{
              formatNumber(
                props.groupOfCampaigns.group.roas_in_previous_week,
                2,
              )
            }}</span>
          </div>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_CLICK ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.CLICKS) &&
            props.groupOfCampaigns.group.daily_cpc_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Cost per click</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_cpc_forecast,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_MILLE ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.IMPRESSIONS) &&
            props.groupOfCampaigns.group.daily_cpi_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Cost per mille</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_cpi_forecast * 1000,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_UNIQUE_MILLE ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.REACH) &&
            props.groupOfCampaigns.group.daily_cpr_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Cost per unique mille</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_cpr_forecast * 1000,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_UNIQUE_CLICK ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.UNIQUE_CLICKS) &&
            props.groupOfCampaigns.group?.daily_cpuc_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Cost per unique click</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_cpuc_forecast,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="flex items-center space-x-4"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.AD_PROFIT ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.ROAS) &&
            props.groupOfCampaigns.group.daily_ad_profit_forecast !== undefined
          "
        >
          <div class="hidden h-[38px] w-[38px] min-[1400px]:block">
            <graph
              v-if="workspaceStore.campaigns_timeframe"
              :timeframe="workspaceStore.campaigns_timeframe"
              :grid="false"
              :fill="true"
              :data="adProfitData"
              :currency="workspaceStore.workspace.currency"
              :uuid="`shared-budget-ad-profit-${props.groupOfCampaigns.group.id}`"
            ></graph>
          </div>
          <div class="items-left flex flex-col">
            <span class="font-sans text-sm text-emerald-300/70"
              >Monthly ad profit</span
            >
            <span class="font-mono text-base text-emerald-300">{{
              currencyStore.format(
                props.groupOfCampaigns.group.daily_ad_profit_forecast *
                  (365.25 / 12),
                workspaceStore.workspace.currency,
              )
            }}</span>
          </div>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.REVENUE &&
            props.groupOfCampaigns.group.daily_revenue_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Monthly ad revenue</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_revenue_forecast *
                (365.25 / 12),
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.CLICKS &&
            props.groupOfCampaigns.group.daily_clicks_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Monthly clicks</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            formatNumber(
              props.groupOfCampaigns.group.daily_clicks_forecast *
                (365.25 / 12),
              0,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.UNIQUE_CLICKS &&
            props.groupOfCampaigns.group.daily_unique_clicks_forecast !==
              undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Monthly unique clicks</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            formatNumber(
              props.groupOfCampaigns.group.daily_unique_clicks_forecast *
                (365.25 / 12),
              0,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.IMPRESSIONS &&
            props.groupOfCampaigns.group.daily_impressions_forecast !==
              undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Monthly impressions</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            formatNumber(
              props.groupOfCampaigns.group.daily_impressions_forecast *
                (365.25 / 12),
              0,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.REACH &&
            props.groupOfCampaigns.group.daily_reach_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Monthly reach</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            formatNumber(
              props.groupOfCampaigns.group.daily_reach_forecast * (365.25 / 12),
              0,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_PURCHASE ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.AVERAGE_CONVERSION_REVENUE) &&
            props.groupOfCampaigns.group.daily_cpp_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Cost per conversion</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_cpp_forecast,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
        <div
          class="items-left flex flex-col"
          v-if="
            (props.groupOfCampaigns.group?.target_metric ===
              TargetMetric.COST_PER_PURCHASE ||
              props.groupOfCampaigns.group?.target_metric ===
                TargetMetric.AVERAGE_CONVERSION_REVENUE) &&
            props.groupOfCampaigns.group.daily_acr_forecast !== undefined
          "
        >
          <span class="font-sans text-sm text-emerald-300/70"
            >Average conversion value</span
          >
          <span class="font-mono text-base text-emerald-300">{{
            currencyStore.format(
              props.groupOfCampaigns.group.daily_acr_forecast,
              workspaceStore.workspace.currency,
            )
          }}</span>
        </div>
      </div>
      <div class="flex items-center justify-between space-x-4">
        <div
          v-if="
            pending_budgeting_algorithm_run &&
            props.groupOfCampaigns?.group.id !== 1
          "
          class="group relative cursor-pointer rounded-full p-3"
          @click="
            () => {
              if (
                !props.groupOfCampaigns.group ||
                props.groupOfCampaigns.group.id === 1
              ) {
                return;
              }
              approval_open = true;
            }
          "
        >
          <LightningBoltIcon
            class="h-5 w-5 opacity-40 group-hover:opacity-100"
          ></LightningBoltIcon>
          <span
            class="absolute -right-1 -top-1 flex min-w-[20px] items-center justify-center rounded-full bg-rose-500 px-1 py-0.5 text-center text-xs font-bold"
            >1</span
          >
        </div>
        <div
          class="cursor-pointer rounded-full p-3 opacity-40 hover:opacity-100"
          @click="
            () => {
              if (
                !props.groupOfCampaigns.group ||
                props.groupOfCampaigns.group.id === 1
              ) {
                return;
              }
              manage_open = true;
            }
          "
        >
          <GearIcon class="h-5 w-5"></GearIcon>
        </div>
      </div>

      <dialog-wrapper v-model="approval_open">
        <budget-change-approval-dialog
          v-if="pending_budgeting_algorithm_run"
          :run="pending_budgeting_algorithm_run"
          @close="approval_open = false"
          @approved="
            () => {
              approval_open = false;
            }
          "
          @rejected="
            () => {
              approval_open = false;
            }
          "
        ></budget-change-approval-dialog>
      </dialog-wrapper>

      <dialog-wrapper v-model="manage_open">
        <create-budget-dialog
          :shared-budget="props.groupOfCampaigns.group"
          title="Shared budget settings"
          @close="manage_open = false"
          @remove="
            () => {
              manage_open = false;
              remove_open = true;
            }
          "
        ></create-budget-dialog>
      </dialog-wrapper>

      <dialog-wrapper v-model="remove_open">
        <remove-budget-dialog
          :shared-budget="props.groupOfCampaigns.group"
          @cancel="
            () => {
              remove_open = false;
              manage_open = true;
            }
          "
        ></remove-budget-dialog>
      </dialog-wrapper>
    </div>
  </div>
</template>

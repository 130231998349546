<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import LearnMoreAbout from "@/app/docs/learn-more-about.vue";
import create_shared_budget from "@/assets/docs/create-shared-budget.png";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Shared budgets in Charter are how you group your ad campaigns according to
      different metrics you may be targeting.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div
      class="border-l-4 border-[#bf5eff] bg-purple-50 p-4 text-purple-800"
      role="alert"
    >
      <p class="font-bold">Example</p>
      <p>
        You may be running a group of ads across Meta and Google with the target
        metric of Reach to create more brand awareness, with a shared budget of
        $2,000/month.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <learn-more-about
      :route="{
        name: 'docs-shared-budgets',
      }"
      >Shared Budgets</learn-more-about
    >
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="create_shared_budget" />
    </div>
    <p>
      To create a shared budget, click the “create shared budget” button in the
      top right of your dashboard. Input your chosen monthly budget, select your
      target metric, and optionally give the shared budget a name e.g. “Brand
      awareness”. Then click “create shared budget”.
    </p>
    <p>
      Within this shared budget dialog, you can also set
      <a href="https://usecharter.io/docs/campaign-thresholds"> Thresholds</a>
      and adjust
      <a href="https://usecharter.io/docs/budget-change-approval"> Approval</a>
      settings.
    </p>
  </div>
</template>

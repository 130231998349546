<script setup lang="ts">
import {
  CalendarIcon,
  ExternalLinkIcon,
  GearIcon,
  InfoCircledIcon,
  MixerHorizontalIcon,
  PlayIcon,
} from "@radix-icons/vue";
import { computed, PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import InputCurrency from "@/components/form/controls/input-currency.vue";
import InputDecimal from "@/components/form/controls/input-decimal.vue";
import InputSelect from "@/components/form/controls/input-select.vue";
import FormError from "@/components/form/form-error.vue";
import FormGroup from "@/components/form/form-group.vue";
import StyledButton from "@/components/form/styled-button.vue";
import Popover from "@/components/popover.vue";
import Scrollview from "@/components/scrollview.vue";
import Toggle from "@/components/toggle.vue";
import { SharedBudget, SharedBudgetRaw } from "@/dtos/shared-budget.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { TargetMetric, targetMetricName } from "@/enums/target-metric.ts";
import { api } from "@/helpers/api.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  sharedBudget: {
    type: Object as PropType<SharedBudget>,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits<{
  (e: "close"): void;
  (e: "remove"): void;
}>();
const workspaceStore = useWorkspaceStore();

const normalizeAndParseFloat = (
  value: string | number,
  thousands_separator: string,
  decimal_separator: string,
) =>
  parseFloat(
    `${value}`
      .replaceAll(thousands_separator, "")
      .replace(decimal_separator, "."),
  );

enum Tab {
  GENERAL = "general",
  THRESHOLDS = "thresholds",
  SCHEDULING = "scheduling",
  APPROVAL = "approval",
}
const tab = ref<Tab>(Tab.GENERAL);

const formatNumber = (value) => {
  const parts = value.split(".");
  const integerPart = parts[0];
  const decimalPart = parts.length >= 2 ? `.${parts[1]}` : "";
  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimalPart;
};

const name = ref<string>(props.sharedBudget?.name ?? "");
const amount = ref<string>(
  props.sharedBudget?.monthly_budget_ceiling
    ? formatNumber(`${props.sharedBudget?.monthly_budget_ceiling}`)
    : "",
);
const target = ref<TargetMetric | undefined>(
  props.sharedBudget?.target_metric ?? TargetMetric.AD_PROFIT,
);

const name_savable = computed<boolean>(() => {
  if (!props.sharedBudget) {
    return false;
  }
  return (props.sharedBudget.name ?? "") !== (name.value ?? "");
});
const target_metric_savable = computed<boolean>(() => {
  if (!props.sharedBudget) {
    return false;
  }
  return props.sharedBudget.target_metric !== target.value;
});
const amount_savable = computed<boolean>(() => {
  if (!props.sharedBudget) {
    return false;
  }
  if (amount.value === "") {
    return false;
  }
  if (
    normalizeAndParseFloat(
      amount.value,
      workspaceStore.workspace!.currency.thousands_separator,
      workspaceStore.workspace!.currency.decimal_separator,
    ) <= 0
  ) {
    return false;
  }
  return (
    parseFloat(`${props.sharedBudget.monthly_budget_ceiling}`) !==
    normalizeAndParseFloat(
      amount.value.replaceAll(
        new RegExp(workspaceStore.workspace!.currency.thousands_separator, "g"),
        "",
      ),
      workspaceStore.workspace!.currency.thousands_separator,
      workspaceStore.workspace!.currency.decimal_separator,
    )
  );
});
const updateAmount = () => {
  if (!props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateGroupBudget();

  if (errors.value.general.group_budget) {
    submitting.value = false;
    return;
  }

  // submit
  api
    .put("workspace/shared-budget/budget", {
      shared_budget_id: props.sharedBudget.id,
      amount: normalizeAndParseFloat(
        amount.value,
        workspaceStore.workspace!.currency.thousands_separator,
        workspaceStore.workspace!.currency.decimal_separator,
      ),
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateTargetMetric = () => {
  if (!props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateTargetMetric();

  if (errors.value.general.target_metric) {
    submitting.value = false;
    return;
  }

  // submit
  api
    .put("workspace/shared-budget/target-metric", {
      shared_budget_id: props.sharedBudget.id,
      target: target.value,
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateName = () => {
  if (!props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateGroupName();

  if (errors.value.general.group_name) {
    submitting.value = false;
    return;
  }

  // submit
  api
    .put("workspace/shared-budget/name", {
      shared_budget_id: props.sharedBudget.id,
      name: name.value,
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateThresholds = () => {
  if (!props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validateThresholds();

  if (hasThresholdsTabErrors()) {
    submitting.value = false;
    return;
  }

  // submit
  api
    .put("workspace/shared-budget/thresholds", {
      shared_budget_id: props.sharedBudget.id,
      threshold_roas:
        normalizeAndParseFloat(thresholds.value.roas.value, ",", ".") ?? null,
      threshold_roas_enabled: thresholds.value.roas.enabled,
      threshold_cpp:
        normalizeAndParseFloat(thresholds.value.cpp.value, ",", ".") ?? null,
      threshold_cpp_enabled: thresholds.value.cpp.enabled,
      threshold_cpm:
        normalizeAndParseFloat(thresholds.value.cpm.value, ",", ".") ?? null,
      threshold_cpm_enabled: thresholds.value.cpm.enabled,
      threshold_cpc:
        normalizeAndParseFloat(thresholds.value.cpc.value, ",", ".") ?? null,
      threshold_cpc_enabled: thresholds.value.cpc.enabled,
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
    })
    .finally(() => {
      submitting.value = false;
    });
};
const updateApproval = () => {
  if (!props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // submit
  api
    .put("workspace/shared-budget/approval", {
      shared_budget_id: props.sharedBudget.id,
      budget_change_approval: budget_change_approval.value,
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
    })
    .finally(() => {
      submitting.value = false;
    });
};

const submitting = ref<boolean>(false);

type GeneralErrors = {
  group_budget?: string;
  target_metric?: string;
  group_name?: string;
};
type ThresholdErrors = {
  roas?: string;
  cpp?: string;
  cpm?: string;
  cpc?: string;
};
type Errors = {
  general: GeneralErrors;
  thresholds: ThresholdErrors;
};
const errors = ref<Errors>({
  general: {},
  thresholds: {},
});

const hasGeneralTabErrors = () => {
  return (
    Object.keys(errors.value.general).filter(
      (key) => !!errors.value.general[key],
    ).length >= 1
  );
};
const hasThresholdsTabErrors = () => {
  return (
    Object.keys(errors.value.thresholds).filter(
      (key) => !!errors.value.thresholds[key],
    ).length >= 1
  );
};
const hasErrors = () => {
  return hasGeneralTabErrors() || hasThresholdsTabErrors();
};

const validate = () => {
  validateGeneral();
  validateThresholds();
};
const validateGeneral = () => {
  validateGroupBudget();
  validateTargetMetric();
  validateGroupName();
};
const validateThresholds = () => {
  validateROAS();
  validateCPP();
  validateCPM();
  validateCPC();
};
const validateGroupBudget = () => {
  if (amount.value.trim().length <= 0) {
    errors.value.general.group_budget = "Please enter a monthly budget";
    return;
  }
  const parsed_amount = normalizeAndParseFloat(
    amount.value.trim(),
    workspaceStore.workspace!.currency.thousands_separator,
    workspaceStore.workspace!.currency.decimal_separator,
  );
  if (isNaN(parsed_amount)) {
    errors.value.general.group_budget = "Please enter a valid monthly budget";
    return;
  }
  if (parsed_amount <= 0) {
    errors.value.general.group_budget = "The monthly budget can't be zero";
    return;
  }
  errors.value.general.group_budget = undefined;
};
const validateTargetMetric = () => {
  if (!target.value) {
    errors.value.general.target_metric = "Please select a target metric";
    return;
  }
  errors.value.general.target_metric = undefined;
};
const validateGroupName = () => {
  errors.value.general.group_name = undefined;
};

const validateROAS = () => {
  const has_input = thresholds.value.roas.value.trim().length >= 1;

  if (thresholds.value.roas.enabled && !has_input) {
    errors.value.thresholds.roas = "Please enter a value or disable";
    return;
  }
  if (has_input) {
    const parsed_value = normalizeAndParseFloat(
      thresholds.value.roas.value.trim(),
      ",",
      ".",
    );
    if (isNaN(parsed_value)) {
      errors.value.thresholds.roas = "Please enter a valid value";
      return;
    }
  }
  errors.value.thresholds.roas = undefined;
};
const validateCPP = () => {
  const has_input = thresholds.value.cpp.value.trim().length >= 1;

  if (thresholds.value.cpp.enabled && !has_input) {
    errors.value.thresholds.cpp = "Please enter a value or disable";
    return;
  }
  if (has_input) {
    const parsed_value = normalizeAndParseFloat(
      thresholds.value.cpp.value.trim(),
      workspaceStore.workspace!.currency.thousands_separator,
      workspaceStore.workspace!.currency.decimal_separator,
    );
    if (isNaN(parsed_value)) {
      errors.value.thresholds.cpp = "Please enter a valid value";
      return;
    }
  }
  errors.value.thresholds.cpp = undefined;
};
const validateCPM = () => {
  const has_input = thresholds.value.cpm.value.trim().length >= 1;

  if (thresholds.value.cpm.enabled && !has_input) {
    errors.value.thresholds.cpm = "Please enter a value or disable";
    return;
  }
  if (has_input) {
    const parsed_value = normalizeAndParseFloat(
      thresholds.value.cpm.value.trim(),
      workspaceStore.workspace!.currency.thousands_separator,
      workspaceStore.workspace!.currency.decimal_separator,
    );
    if (isNaN(parsed_value)) {
      errors.value.thresholds.cpm = "Please enter a valid value";
      return;
    }
  }
  errors.value.thresholds.cpm = undefined;
};
const validateCPC = () => {
  const has_input = thresholds.value.cpc.value.trim().length >= 1;

  if (thresholds.value.cpc.enabled && !has_input) {
    errors.value.thresholds.cpc = "Please enter a value or disable";
    return;
  }
  if (has_input) {
    const parsed_value = normalizeAndParseFloat(
      thresholds.value.cpc.value.trim(),
      workspaceStore.workspace!.currency.thousands_separator,
      workspaceStore.workspace!.currency.decimal_separator,
    );
    if (isNaN(parsed_value)) {
      errors.value.thresholds.cpc = "Please enter a valid value";
      return;
    }
  }
  errors.value.thresholds.cpc = undefined;
};

const create = () => {
  if (props.sharedBudget) {
    return;
  }
  if (submitting.value === true) {
    return;
  }
  submitting.value = true;

  // validate
  validate();

  const has_general_errors = hasGeneralTabErrors();
  const has_threshold_errors = hasThresholdsTabErrors();

  if (hasErrors()) {
    const current_tab_has_errors =
      (tab.value === Tab.GENERAL && has_general_errors) ||
      (tab.value === Tab.THRESHOLDS && has_threshold_errors);

    if (!current_tab_has_errors) {
      if (tab.value !== Tab.GENERAL && has_general_errors) {
        tab.value = Tab.GENERAL;
      } else if (tab.value !== Tab.THRESHOLDS && has_threshold_errors) {
        tab.value = Tab.THRESHOLDS;
      }
    }
    submitting.value = false;
    return;
  }

  if (workspaceStore.workspace!.id === 1) {
    submitting.value = false;
    return;
  }

  // submit
  api
    .post("workspace/shared-budget", {
      workspace_id: workspaceStore.workspace!.id,
      name: name.value,
      target: target.value,
      amount: normalizeAndParseFloat(
        amount.value,
        workspaceStore.workspace!.currency.thousands_separator,
        workspaceStore.workspace!.currency.decimal_separator,
      ),
      threshold_roas:
        normalizeAndParseFloat(thresholds.value.roas.value, ",", ".") ?? null,
      threshold_roas_enabled: thresholds.value.roas.enabled,
      threshold_cpp:
        normalizeAndParseFloat(thresholds.value.cpp.value, ",", ".") ?? null,
      threshold_cpp_enabled: thresholds.value.cpp.enabled,
      threshold_cpm:
        normalizeAndParseFloat(thresholds.value.cpm.value, ",", ".") ?? null,
      threshold_cpm_enabled: thresholds.value.cpm.enabled,
      threshold_cpc:
        normalizeAndParseFloat(thresholds.value.cpc.value, ",", ".") ?? null,
      threshold_cpc_enabled: thresholds.value.cpc.enabled,
      budget_change_approval: budget_change_approval.value,
    })
    .then((data: unknown) => {
      workspaceStore.mergeRawSharedBudget(
        data as SharedBudgetRaw,
        workspaceStore.workspace!,
      );
      emit("close");
    })
    .finally(() => {
      submitting.value = false;
    });
};

const approval_savable = computed<boolean>(() => {
  if (!props.sharedBudget) {
    return false;
  }
  return (
    props.sharedBudget.budget_change_approval !== budget_change_approval.value
  );
});
type Threshold = {
  label: string;
  tooltip: string;
  enabled: boolean;
  value: string;
  savable: (key: string, threshold: Threshold) => boolean;
};
type Thresholds = {
  roas: Threshold;
  cpp: Threshold;
  cpm: Threshold;
  cpc: Threshold;
};

const thresholds_savable = computed<boolean>(() => {
  if (!props.sharedBudget) {
    return false;
  }
  for (let i = 0, keys = Object.keys(thresholds.value); i < keys.length; i++) {
    const savable = thresholds.value[keys[i]].savable(
      keys[i],
      thresholds.value[keys[i]],
    );
    if (savable) {
      return savable;
    }
  }
  return false;
});

const roasThresholdSavable = (key: string, threshold: Threshold): boolean => {
  if (!props.sharedBudget) {
    return false;
  }
  if (threshold.enabled !== props.sharedBudget[`threshold_${key}_enabled`]) {
    return true;
  }
  const parsed_input = normalizeAndParseFloat(threshold.value, ",", ".");
  const parsed_existing = parseFloat(props.sharedBudget[`threshold_${key}`]);
  if (isNaN(parsed_input) && isNaN(parsed_existing)) {
    return false;
  }
  return parsed_input !== parsed_existing;
};

const currencyThresholdSavable = (
  key: string,
  threshold: Threshold,
): boolean => {
  if (!props.sharedBudget) {
    return false;
  }
  if (threshold.enabled !== props.sharedBudget[`threshold_${key}_enabled`]) {
    return true;
  }
  const parsed_input = normalizeAndParseFloat(
    threshold.value,
    workspaceStore.workspace!.currency.thousands_separator,
    workspaceStore.workspace!.currency.decimal_separator,
  );
  const parsed_existing = parseFloat(props.sharedBudget[`threshold_${key}`]);
  if (isNaN(parsed_input) && isNaN(parsed_existing)) {
    return false;
  }
  return parsed_input !== parsed_existing;
};
const thresholds = ref<Thresholds>({
  roas: {
    label: "ROAS",
    tooltip: "Return On Ad Spend",
    enabled: props.sharedBudget
      ? props.sharedBudget.threshold_roas_enabled
      : false,
    value: props.sharedBudget?.threshold_roas
      ? formatNumber(`${props.sharedBudget?.threshold_roas}`)
      : "",
    savable: roasThresholdSavable,
  },
  cpp: {
    label: "CPP",
    tooltip: "Cost Per Purchase",
    enabled: props.sharedBudget
      ? props.sharedBudget.threshold_cpp_enabled
      : false,
    value: props.sharedBudget?.threshold_cpp
      ? formatNumber(`${props.sharedBudget?.threshold_cpp}`)
      : "",
    savable: currencyThresholdSavable,
  },
  cpm: {
    label: "CPM",
    tooltip: "Cost Per Mille",
    enabled: props.sharedBudget
      ? props.sharedBudget.threshold_cpm_enabled
      : false,
    value: props.sharedBudget?.threshold_cpm
      ? formatNumber(`${props.sharedBudget?.threshold_cpm}`)
      : "",
    savable: currencyThresholdSavable,
  },
  cpc: {
    label: "CPC",
    tooltip: "Cost Per Click",
    enabled: props.sharedBudget
      ? props.sharedBudget.threshold_cpc_enabled
      : false,
    value: props.sharedBudget?.threshold_cpc
      ? formatNumber(`${props.sharedBudget?.threshold_cpc}`)
      : "",
    savable: currencyThresholdSavable,
  },
});

const budget_change_approval = ref<boolean>(
  props.sharedBudget ? props.sharedBudget.budget_change_approval : true,
);
</script>
<template>
  <div>
    <dialog-titlebar
      @close="emit('close')"
      :title="props.title"
      :title-border="true"
      :padding="false"
    ></dialog-titlebar>
    <dialog-body :padding="false" v-if="workspaceStore.workspace">
      <div class="flex w-auto">
        <div
          class="flex w-[210px] flex-col justify-between border-0 border-r-[1px] border-white/10 py-[10px] pl-[3px] pr-[17px]"
        >
          <div class="flex flex-col space-y-[2px]">
            <div
              @click="tab = Tab.GENERAL"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === Tab.GENERAL ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <GearIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === Tab.GENERAL ? `text-white/50` : `text-white/30`}`"
              ></GearIcon>
              <span>General</span>
            </div>
            <div
              @click="tab = Tab.THRESHOLDS"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === Tab.THRESHOLDS ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <MixerHorizontalIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === Tab.THRESHOLDS ? `text-white/50` : `text-white/30`}`"
              ></MixerHorizontalIcon>
              <span>Thresholds</span>
            </div>
            <div
              v-if="false"
              @click="tab = Tab.SCHEDULING"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === Tab.SCHEDULING ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <CalendarIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === Tab.SCHEDULING ? `text-white/50` : `text-white/30`}`"
              ></CalendarIcon>
              <span>Scheduling</span>
            </div>
            <div
              @click="tab = Tab.APPROVAL"
              :class="`flex h-[38px] min-w-[202px] cursor-pointer select-none items-center space-x-[14px] rounded-[3px] from-white/10 to-white/4 pl-[20px] pr-[56px]  ${tab === Tab.APPROVAL ? `bg-gradient-to-r font-semibold` : `font-light hover:bg-white/3`}`"
            >
              <PlayIcon
                :class="`h-auto w-[17px] transition-all duration-75 ${tab === Tab.APPROVAL ? `text-white/50` : `text-white/30`}`"
              ></PlayIcon>
              <span>Approval</span>
            </div>
          </div>
          <div class="flex flex-col space-y-[2px] pb-3 pl-6">
            <span
              v-if="props.sharedBudget"
              @click="emit('remove')"
              class="cursor-pointer select-none text-sm text-white/20 transition-all duration-75 hover:text-rose-600"
              >Delete group</span
            >
          </div>
        </div>
        <div class="flex flex-col">
          <scrollview class="h-[550px] w-[675px]">
            <div v-if="tab === Tab.GENERAL">
              <form-group>
                <template #title>Monthly budget</template>
                <template #description
                  >This is the total budget shared across all campaigns in this
                  group. Charter will not exceed this total budget when
                  allocating between campaigns in this group.</template
                >
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input-currency
                      class="w-[300px]"
                      :currency="workspaceStore.workspace!.currency"
                      v-model="amount"
                      :readonly="submitting"
                      @input="
                        () => {
                          if (errors.general.group_budget) {
                            validateGroupBudget();
                          }
                        }
                      "
                    ></input-currency>
                    <styled-button
                      @click="updateAmount"
                      v-if="props.sharedBudget"
                      :disabled="!amount_savable"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.group_budget">{{
                  errors.general.group_budget
                }}</template>
                <template #info
                  ><div class="flex items-center space-x-1.5">
                    <span>Learn more about</span>
                    <router-link
                      target="_blank"
                      :to="'/'"
                      class="link flex items-center space-x-1"
                    >
                      <span>Shared Budgets</span>
                      <ExternalLinkIcon class="h-auto w-4" />
                    </router-link></div
                ></template>
              </form-group>
              <form-group>
                <template #title>Target metric</template>
                <template #description
                  >The target metric you select for each shared budget is the
                  metric Charter’s algorithm will optimise for across the
                  campaigns within that shared budget.</template
                >
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input-select
                      class="input w-[300px]"
                      v-model="target"
                      :readonly="submitting"
                      @change="
                        () => {
                          if (errors.general.target_metric) {
                            validateTargetMetric();
                          }
                        }
                      "
                    >
                      <option :value="TargetMetric.AD_PROFIT">
                        {{ targetMetricName(TargetMetric.AD_PROFIT) }}
                        (recommended)
                      </option>
                      <option :value="TargetMetric.REVENUE">
                        {{ targetMetricName(TargetMetric.REVENUE) }}
                      </option>
                      <option :value="TargetMetric.IMPRESSIONS">
                        {{ targetMetricName(TargetMetric.IMPRESSIONS) }}
                      </option>
                      <option :value="TargetMetric.REACH">
                        {{ targetMetricName(TargetMetric.REACH) }}
                      </option>
                      <option :value="TargetMetric.CLICKS">
                        {{ targetMetricName(TargetMetric.CLICKS) }}
                      </option>
                      <option :value="TargetMetric.UNIQUE_CLICKS">
                        {{ targetMetricName(TargetMetric.UNIQUE_CLICKS) }}
                      </option>
                      <option :value="TargetMetric.ROAS">
                        {{ targetMetricName(TargetMetric.ROAS) }}
                      </option>
                      <option :value="TargetMetric.COST_PER_PURCHASE">
                        {{ targetMetricName(TargetMetric.COST_PER_PURCHASE) }}
                      </option>
                      <option :value="TargetMetric.AVERAGE_CONVERSION_REVENUE">
                        {{
                          targetMetricName(
                            TargetMetric.AVERAGE_CONVERSION_REVENUE,
                          )
                        }}
                      </option>
                    </input-select>
                    <styled-button
                      @click="updateTargetMetric"
                      v-if="props.sharedBudget"
                      :disabled="!target_metric_savable"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.target_metric">{{
                  errors.general.target_metric
                }}</template>
                <template #info
                  ><div class="flex items-center space-x-1.5">
                    <span>Learn more about</span>
                    <router-link
                      target="_blank"
                      :to="'/'"
                      class="link flex items-center space-x-1"
                    >
                      <span>Target Metrics</span>
                      <ExternalLinkIcon class="h-auto w-4" />
                    </router-link></div
                ></template>
              </form-group>
              <form-group :border="false">
                <template #title
                  >Group name
                  <span class="text-white/30">(optional)</span></template
                >
                <!--                <template #description-->
                <!--                  >Give this group a name - we recommend including the goal for-->
                <!--                  this group in your campaign name.</template-->
                <!--                >-->
                <template #controls>
                  <div class="flex items-center justify-between">
                    <input class="w-[300px]" v-model="name" />
                    <styled-button
                      @click="updateName"
                      v-if="props.sharedBudget"
                      :submitting="submitting"
                      :type="ButtonType.PRIMARY"
                      :disabled="!name_savable"
                    >
                      Save
                    </styled-button>
                  </div>
                </template>
                <template #error v-if="errors.general.group_name">{{
                  errors.general.group_name
                }}</template>
              </form-group>
            </div>
            <div v-if="tab === Tab.THRESHOLDS">
              <form-group>
                <template #title>Thresholds</template>
                <template #description
                  >If you want to give Charter’s algorithm boundaries in
                  addition to your shared budgets, you can use thresholds.
                  Thresholds are maximum/minimums that you can set for Charter
                  to operate within.</template
                >
                <template #controls>
                  <table class="mb-3.5 w-full">
                    <tbody>
                      <tr
                        v-for="(key, i) in Object.keys(thresholds)"
                        :key="key"
                      >
                        <td style="white-space: nowrap; vertical-align: top">
                          <div
                            :class="`mt-1.5 flex items-center space-x-3 pr-5 ${i >= 1 ? `pt-3.5` : ``}`"
                          >
                            <span>{{ thresholds[key].label }}</span>
                            <popover>
                              <template #trigger
                                ><InfoCircledIcon
                                  class="h-auto w-4 opacity-50 transition-all duration-75 hover:opacity-100"
                                ></InfoCircledIcon
                              ></template>

                              <template #content>
                                <div
                                  class="max-w-[280px] whitespace-break-spaces text-sm"
                                >
                                  {{ thresholds[key].tooltip }}
                                </div>
                              </template>
                            </popover>
                          </div>
                        </td>
                        <td
                          :class="`${i >= 1 ? `pt-3.5` : ``}`"
                          style="width: 99%; vertical-align: top"
                        >
                          <input-decimal
                            v-if="key === 'roas'"
                            v-model="thresholds[key].value"
                            :dp="4"
                            :disabled="!thresholds[key].enabled"
                            :readonly="submitting"
                            @input="
                              () => {
                                if (errors.thresholds[key]) {
                                  validateThresholds();
                                }
                              }
                            "
                          ></input-decimal>
                          <input-currency
                            v-else
                            v-model="thresholds[key].value"
                            :currency="workspaceStore.workspace!.currency"
                            :disabled="!thresholds[key].enabled"
                            :readonly="submitting"
                            @input="
                              () => {
                                if (errors.thresholds[key]) {
                                  validateThresholds();
                                }
                              }
                            "
                          ></input-currency>
                          <form-error
                            v-if="!!errors.thresholds[key]"
                            class="mb-1"
                            >{{ errors.thresholds[key] }}</form-error
                          >
                        </td>
                        <td
                          :class="`${i >= 1 ? `pt-3.5` : ``}`"
                          style="white-space: nowrap; vertical-align: top"
                        >
                          <div class="mt-1.5 pl-5">
                            <toggle
                              v-model="thresholds[key].enabled"
                              :label-first="true"
                              @change="
                                () => {
                                  if (errors.thresholds[key]) {
                                    validateThresholds();
                                  }
                                }
                              "
                              ><span
                                :class="`text-sm ${thresholds[key].enabled ? `` : `opacity-20`}`"
                                >Enabled</span
                              ></toggle
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template #info
                  ><div class="flex items-center space-x-1.5">
                    <span>Learn more about</span>
                    <router-link
                      target="_blank"
                      :to="'/'"
                      class="link flex items-center space-x-1"
                    >
                      <span>Campaign Thresholds</span>
                      <ExternalLinkIcon class="h-auto w-4" />
                    </router-link></div
                ></template>
                <template #action
                  ><div class="flex items-center" v-if="props.sharedBudget">
                    <styled-button
                      :submitting="submitting"
                      :disabled="!thresholds_savable"
                      :type="ButtonType.PRIMARY"
                      @click="updateThresholds"
                      >Save</styled-button
                    >
                  </div></template
                >
              </form-group>
            </div>
            <div v-if="tab === Tab.APPROVAL">
              <form-group>
                <template #title>Budget change approval</template>
                <template #description
                  >Choose whether you want to stay in control of budget changes
                  or prefer for our algorithm to be fully automated. You may
                  change this option later.</template
                >
                <template #controls>
                  <div class="mb-3.5 flex flex-col space-y-4">
                    <div
                      :class="`flex cursor-pointer flex-col space-y-1 rounded-[6px] border-2 px-5 py-3 transition-all duration-75 ${budget_change_approval === true ? `border-[#29EAB2]/60 bg-[#29EAB2]/10` : `border-white/10 hover:border-[#29EAB2]/15 hover:bg-[#29EAB2]/4`}`"
                      @click="budget_change_approval = true"
                    >
                      <span>Require approval</span>
                      <span class="text-sm font-light opacity-50"
                        >We'll only make budget changes with your approval.
                        You'll receive an email notification when changes are
                        ready for you to review.</span
                      >
                    </div>
                    <div
                      :class="`flex cursor-pointer flex-col space-y-1 rounded-[6px] border-2 px-5 py-3 transition-all duration-75 ${budget_change_approval === false ? `border-[#29EAB2]/60 bg-[#29EAB2]/10` : `border-white/10 hover:border-[#29EAB2]/15 hover:bg-[#29EAB2]/4`}`"
                      @click="budget_change_approval = false"
                    >
                      <span>Fully automated</span>
                      <span class="text-sm font-light opacity-50"
                        >Our algorithm will control the budget of your campaigns
                        to maximize your target metric without the need for
                        approval.</span
                      >
                    </div>
                  </div>
                </template>
                <template #info
                  ><div class="flex items-center space-x-1.5">
                    <span>Learn more about</span>
                    <router-link
                      target="_blank"
                      :to="'/'"
                      class="link flex items-center space-x-1"
                    >
                      <span>Budget Change Approval</span>
                      <ExternalLinkIcon class="h-auto w-4" />
                    </router-link></div
                ></template>
                <template #action
                  ><div class="flex items-center" v-if="props.sharedBudget">
                    <styled-button
                      :submitting="submitting"
                      :disabled="!approval_savable"
                      :type="ButtonType.PRIMARY"
                      @click="updateApproval"
                      >Save</styled-button
                    >
                  </div></template
                >
              </form-group>
            </div>
          </scrollview>
          <div
            v-if="!props.sharedBudget"
            class="flex h-[60px] items-center justify-between border-0 border-t-[1px] border-white/10 bg-black px-7"
          >
            <div></div>
            <styled-button
              @click="create"
              :type="ButtonType.PRIMARY"
              :submitting="submitting"
            >
              Create shared budget
            </styled-button>
          </div>
        </div>
      </div>
    </dialog-body>
  </div>
</template>

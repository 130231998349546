<script setup lang="ts">
import { computed, PropType } from "vue";

import AdChannelIcon from "@/app/ad-channel-icon.vue";
import { BudgetChange } from "@/dtos/budget-change.ts";
import { BudgetingAlgorithmRun } from "@/dtos/budgeting-algorithm-run.ts";
import { Campaign } from "@/dtos/campaign.ts";
import { formatNumber } from "@/helpers/formatting.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const props = defineProps({
  run: {
    type: Object as PropType<BudgetingAlgorithmRun>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();
const currencyStore = useCurrencyStore();

type CampaignWithPendingChange = {
  campaign: Campaign;
  change?: BudgetChange;
  effective_daily_spend: number;
  effective_daily_spend_delta?: number;
  daily_revenue_forecast?: number;
  daily_revenue_forecast_delta?: number;
};

const rows = computed<CampaignWithPendingChange[] | undefined>(() => {
  if (!workspaceStore.workspace) {
    return undefined;
  }
  const items: CampaignWithPendingChange[] = [];

  // for each campaign in the shared budget
  for (let i = 0; i < workspaceStore.workspace.campaigns.length; i++) {
    if (
      workspaceStore.workspace.campaigns[i].shared_budget_id !==
      props.run.shared_budget_id
    ) {
      continue;
    }
    // find the corresponding budget change in this run if one exists
    let found_change: BudgetChange | undefined = undefined;
    for (let ii = 0; ii < props.run.changes.length; ii++) {
      if (
        workspaceStore.workspace.campaigns[i].id ===
        props.run.changes[ii].campaign_id
      ) {
        found_change = props.run.changes[ii];
        break;
      }
    }
    items.push({
      campaign: workspaceStore.workspace.campaigns[i],
      change: found_change,
      effective_daily_spend: found_change
        ? found_change.effective_daily_budget_after
        : workspaceStore.workspace.campaigns[i].effective_daily_budget,
      effective_daily_spend_delta: found_change
        ? found_change.effective_daily_budget_after -
          found_change.effective_daily_budget_before
        : undefined,
      daily_revenue_forecast: found_change
        ? found_change.daily_revenue_forecast_after
        : workspaceStore.workspace.campaigns[i].daily_revenue_forecast,
      daily_revenue_forecast_delta: found_change
        ? found_change.daily_revenue_forecast_after -
          found_change.daily_revenue_forecast_before
        : undefined,
    });
  }

  return items;
});
</script>
<template>
  <table class="w-full" v-if="workspaceStore.workspace">
    <thead>
      <tr>
        <th
          class="pb-2 text-left text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Campaign
        </th>
        <th
          class="pb-2 text-center text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Budget
        </th>
        <th
          class="pb-2 text-center text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          ROAS
        </th>
        <th
          class="whitespace-nowrap pb-2 text-right text-sm font-light uppercase opacity-50"
          style="width: 25%; vertical-align: top"
        >
          Ad profit forecast
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        :class="row.change === undefined ? `opacity-30` : ``"
        v-for="row in rows"
        :key="row.campaign.id"
      >
        <td
          style="white-space: nowrap; vertical-align: top"
          class="py-1.5 pr-10 text-sm font-medium"
        >
          <div class="flex items-center space-x-3 text-sm">
            <ad-channel-icon
              :type="row.campaign.ad_channel_type"
              class="h-4 w-4"
            ></ad-channel-icon>
            <span>{{ row.campaign.name }}</span>
          </div>
        </td>
        <td
          class="whitespace-nowrap py-1.5 text-center font-mono text-sm"
          style="width: 33%; vertical-align: top"
        >
          <span>{{
            currencyStore.format(
              row.effective_daily_spend * (365.25 / 12),
              workspaceStore.workspace.currency,
            )
          }}</span
          >{{ " " }}
          <span
            :class="`text-sm font-bold ${row.effective_daily_spend_delta >= 0 ? `text-emerald-400` : `text-rose-500`}`"
            v-if="
              row.effective_daily_spend_delta &&
              row.effective_daily_spend_delta !== 0
            "
            >({{ row.effective_daily_spend_delta >= 0 ? `+` : ``
            }}{{
              currencyStore.format(
                row.effective_daily_spend_delta * (365.25 / 12),
                workspaceStore.workspace.currency,
              )
            }})</span
          >
        </td>
        <td
          v-if="row.campaign.roas_in_previous_week"
          class="py-1.5 text-center font-mono text-sm opacity-75"
          style="width: 33%; vertical-align: top"
        >
          {{ formatNumber(row.campaign.roas_in_previous_week, 2) }}
        </td>
        <td
          class="whitespace-nowrap py-1.5 text-right font-mono text-sm font-medium"
          style="width: 33%; vertical-align: top"
        >
          <template v-if="row.daily_revenue_forecast">
            <span>{{
              currencyStore.format(
                row.daily_revenue_forecast * (365.25 / 12),
                workspaceStore.workspace.currency,
              )
            }}</span
            >{{ " " }}
            <span
              :class="`text-sm font-bold ${row.daily_revenue_forecast_delta >= 0 ? `text-emerald-400` : `text-rose-500`}`"
              v-if="
                row.daily_revenue_forecast_delta &&
                row.daily_revenue_forecast_delta !== 0
              "
              >({{ row.daily_revenue_forecast_delta >= 0 ? `+` : ``
              }}{{
                currencyStore.format(
                  row.daily_revenue_forecast_delta * (365.25 / 12),
                  workspaceStore.workspace.currency,
                )
              }})</span
            >
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { watchOnce } from "@vueuse/core";
import { computed, PropType, ref } from "vue";

import BlockGrid from "@/app/workspace/campaigns/block-grid.vue";
import CampaignBlock from "@/app/workspace/campaigns/blocks/block.vue";
import GroupTitle from "@/app/workspace/campaigns/shared-budgets/shared-budget-title.vue";
import { GroupedCampaigns } from "@/dtos/grouped-campaigns.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();

const props = defineProps({
  groupOfCampaigns: {
    type: Object as PropType<GroupedCampaigns>,
    required: true,
  },
});

const open = ref<boolean>(false);

const loaded = () => {
  open.value = (workspaceStore.groupedCampaigns?.length ?? 0) < 2;
};

if (workspaceStore.workspace) {
  loaded();
} else {
  watchOnce(() => workspaceStore.workspace, loaded);
}

const has_groups = computed<boolean | undefined>(() => {
  if (!workspaceStore.groupedCampaigns) {
    return undefined;
  }
  return (
    workspaceStore.groupedCampaigns.length >= 2 ||
    (workspaceStore.groupedCampaigns.length == 1 &&
      !!workspaceStore.groupedCampaigns[0].group)
  );
});
</script>
<template>
  <div v-if="workspaceStore.groupedCampaigns">
    <group-title
      :group-of-campaigns="props.groupOfCampaigns"
      :open="open"
      @open="open = true"
      @close="open = false"
      v-if="has_groups === true"
    ></group-title>
    <template v-if="!has_groups || open">
      <block-grid
        class="mb-10 mt-4"
        v-if="props.groupOfCampaigns.campaigns.length >= 1"
      >
        <campaign-block
          v-for="campaign in props.groupOfCampaigns.campaigns"
          :key="campaign.id"
          :campaign="campaign"
          :group="props.groupOfCampaigns.group"
        >
        </campaign-block>
      </block-grid>
      <div class="mb-10 px-9" v-else>
        <span class="opacity-20">(Empty)</span>
      </div>
    </template>
  </div>
</template>

import { defineStore } from "pinia";

import { Campaign, CampaignRaw } from "@/dtos/campaign.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { useCurrencyStore } from "@/stores/currency.ts";

export const useCampaignStore = defineStore("campaign", () => {
  const currencyStore = useCurrencyStore();

  const convertRawCampaign = (campaign_raw: CampaignRaw): Campaign => {
    return nullsToUndefined({
      ...campaign_raw,
      currency: currencyStore.find(campaign_raw.currency_id)!,
      time_series: campaign_raw.time_series.reverse(),
    });
  };

  return {
    convertRawCampaign,
  };
});

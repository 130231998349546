import { defineStore } from "pinia";

import { SharedBudget, SharedBudgetRaw } from "@/dtos/shared-budget.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { useAccountStore } from "@/stores/account.ts";

export const useSharedBudgetStore = defineStore("shared-budget", () => {
  const accountStore = useAccountStore();

  const convertRawSharedBudget = (
    shared_budget_raw: SharedBudgetRaw,
  ): SharedBudget => {
    return nullsToUndefined({
      ...shared_budget_raw,
      account: accountStore.find(shared_budget_raw.account_id)!,
      time_series: shared_budget_raw.time_series.reverse(),
      // campaigns: workspace_raw.campaigns.map((campaign: CampaignRaw) =>
      //   campaignStore.convertRawCampaign(campaign),
      // ),
    }) as SharedBudget;
  };
  return {
    convertRawSharedBudget,
  };
});

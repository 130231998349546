<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import LearnMoreAbout from "@/app/docs/learn-more-about.vue";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Accounts in Charter are organised with workspaces. Workspaces are often
      used to separate clients if you’re a marketing agency, or if you’re a
      business you can use them to separate brands. They’re essentially top
      level organisation within your account.
    </p>
    <p>
      Note: if you’re wanting to have two separate billing set ups, you’ll need
      to do that using two separate accounts.
    </p>
    <learn-more-about
      :route="{
        name: 'docs-billing',
      }"
      >Billing</learn-more-about
    >
  </div>
</template>

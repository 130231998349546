<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();
const privacy_url = `${window.location.origin}${router.resolve({ name: "privacy" }).fullPath}`;
</script>
<template>
  <div
    class="prose prose-base mx-auto mb-20 w-full max-w-4xl dark:prose-invert"
  >
    <p class="text-sm">Last Updated: 14th November 2024</p>
    <p>
      We are PRECISION ADTECH LTD, doing business as Charter (<span
        class="font-black"
        >'Company'</span
      >, <span class="font-black">'we'</span>,
      <span class="font-black">'us'</span>, or
      <span class="font-black">'our'</span>), a company registered in the United
      Kingdom at 27 Old Gloucester Street, London, United Kingdom, WC1N 3AX.
    </p>
    <p>
      We operate the website
      <a target="_blank" href="https://usecharter.io">https://usecharter.io</a>
      (the <span class="font-black">'Site'</span>), the mobile application
      Charter (the <span class="font-black">'App'</span>), as well as any other
      related documents, products, software and services that refer or link to
      these Terms of Service (the
      <span class="font-black">'Terms of Service'</span>) (collectively, the
      <span class="font-black">'Services'</span>).
    </p>
    <p>
      Charter provides publishing, analysis and targeting optimisation tools to
      assist users with managing online paid advertisements. Charter makes this
      Software as a Service (SaaS) available to you via the Site and App on a
      subscription basis for the purpose of managing online paid advertisements.
    </p>
    <p>
      You can contact us by email at
      <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>
    </p>
    <p>
      This Terms of Service is a legal agreement between you (<span
        class="font-black"
        >'you'</span
      >), whether personally or on behalf of an entity (<span class="font-black"
        >'you'</span
      >) and Charter, concerning your access to and use of the Services. You
      agree that by accessing the Services, you have read, understood, and
      agreed to be bound by all of these Legal Terms.
    </p>

    <h3>
      IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY
      PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE
      IMMEDIATELY.
    </h3>

    <p>
      We will provide you with prior notice of any scheduled changes to the
      Services you are using. The modified Terms of Service will become
      effective upon posting or notifying you by email at
      <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>, as stated in
      the email message. By continuing to use the Services after the effective
      date of any changes, you agree to be bound by the modified terms.
    </p>
    <p>
      You should print a copy of this Terms of Service for future reference.
    </p>

    <h3>1. AGREED TERMS</h3>
    <p>1.1 The following defined terms are used in this Terms of Service:</p>
    <ol>
      <li>
        <span class="font-black">Authorised Users</span> means the employees,
        agents and independent contractors of you, your subsidiaries and
        affiliates, who you authorise to use the Services.
      </li>
      <li>
        <span class="font-black">Good Industry Practice</span> means the
        exercise of that degree of skill, care, prudence, efficiency, foresight
        and timeliness as would be expected from a leading company within the
        relevant industry or business sector
      </li>
      <li>
        <span class="font-black">User Data</span> means the data inputted by or
        on behalf of you, for the purpose of using or facilitating your use of
        the Services and any data generated by, or derived from your use of the
        Services, whether hosted or stored within the Services or elsewhere.
      </li>
      <li>
        <span class="font-black">Support Services</span> means the standard
        support services provided in relation to the Services by or on behalf of
        us to you.
      </li>
      <li>
        <span class="font-black">Subscription</span> means the user
        subscription(s) purchased by you, or on your behalf, from us (or an
        entity authorised by us to provide them, in accordance with the terms of
        the agreement you hold with that entity), which entitle Authorised Users
        to access and use the Services in accordance with this agreement.
      </li>
      <li>
        <span class="font-black">Viruses</span> means any thing or device
        (including any software, code, file or programme) which may prevent,
        impair or otherwise adversely affect the operation of any computer
        software, hardware or network, any telecommunications service, equipment
        or network or any other service or device; prevent, impair or otherwise
        adversely affect access to or the operation of any programme or data,
        including the reliability of any programme or data (whether by
        rearranging, altering or erasing the programme or data in whole or part
        or otherwise); or adversely affect the user experience, including worms,
        trojan horses, viruses and other similar things or devices.
      </li>
      <li>
        <span class="font-black">Vulnerability</span> means a weakness in the
        computational logic (for example, code) found in software and hardware
        components that, when exploited, results in a negative impact to
        confidentiality, integrity, or availability, and the term
        Vulnerabilities shall be construed accordingly.
      </li>
    </ol>
    <p>
      1.2 In consideration of payment by you of the agreed Subscription fee and
      you agreeing to abide by the terms of this Terms of Service, we grant to
      you a limited, non-exclusive, non-transferable, revocable licence, without
      the right to sublicense, to access and use the Services on the terms of
      this Terms of Service, solely for your internal business operations.
    </p>
    <p>
      1.3 You may be required to register to use the Services. You agree to keep
      your password confidential and will be responsible for all use of your
      account and password. We reserve the right to remove, reclaim, or change a
      username you select if we determine, in our sole discretion, that such
      username is inappropriate, obscene, or otherwise objectionable. You
      represent and warrant that:
    </p>
    <ol>
      <li>
        all registration information you submit will be true, accurate, current,
        and complete; and
      </li>
      <li>
        you will maintain the accuracy of such information and promptly update
        such registration information as necessary.
      </li>
    </ol>
    <p>1.4 You shall:</p>
    <ol>
      <li>
        provide us with:
        <ol type="a">
          <li>
            all necessary cooperation in relation to this Terms of Service; and
          </li>
          <li>
            all necessary access to such information as may be required by us,
            to the extent required to provide the Services including but not
            limited to User Data, security access information and configuration
            services;
          </li>
        </ol>
      </li>
      <li>
        without affecting your other obligations under this Terms of Service,
        comply with all applicable laws and regulations with respect to your
        activities under this Terms of Service; and
      </li>
      <li>
        ensure that your network and systems comply with the relevant
        specifications provided by us from time to time.
      </li>
    </ol>
    <p>
      1.5 You shall have sole responsibility for the legality, reliability,
      integrity, accuracy and quality of all User Data. You hereby license us to
      use the User Data for:
    </p>
    <ol>
      <li>the proper performance of the Services;</li>
      <li>
        the purposes set out in our Privacy Policy as described in condition
        18.; and
      </li>
      <li>
        all other purposes relevant to the proper exercise of our rights and
        obligations under this agreement.
      </li>
    </ol>
    <p>1.6 You undertake that:</p>
    <ol>
      <li>
        the maximum number of Authorised Users that you authorise to access and
        use the Services shall not exceed the number specified by the User
        Subscription you have purchased from time to time;
      </li>
      <li>
        you will not allow or suffer any Subscription to be used by more than
        one individual Authorised User unless it has been reassigned in its
        entirety to another individual Authorised User, in which case the prior
        Authorised User shall no longer have any right to access or user the
        Services, unless specified in your Subscription.
      </li>
      <li>
        you shall, no more frequently than once per year in the case of physical
        audits, permit us or our designated auditor to audit the Services to
        verify that your use of the Services does not exceed the total number of
        Authorised Users specified by the User Subscription you have purchased.
        This audit may take place physically on your premises, or remotely, at
        our option, and we may deploy reasonable online audit tools via the
        Services for these purposes;
      </li>
      <li>
        you shall supervise and control use of the Services and ensure they are
        used by your employees and representatives only in accordance with the
        terms of this Terms of Service; and
      </li>
      <li>
        you shall comply with all applicable technology control or export laws
        and regulations.
      </li>
    </ol>

    <h3>2. RESTRICTIONS</h3>
    <p>
      2.1 Except as expressly set out in this Terms of Service or as permitted
      by any local law which is incapable of exclusion by agreement between the
      parties, you shall not:
    </p>
    <ol>
      <li>
        attempt to copy, modify, duplicate, create derivative works from, frame,
        mirror, republish, download, display, transmit, or distribute all or any
        portion of the Services (as applicable) in any form or media or by any
        means; or
      </li>
      <li>
        attempt to de-compile, reverse compile, disassemble, reverse engineer or
        otherwise reduce to human-perceivable form all or any part of the
        Services;
      </li>
      <li>
        access all or any part of the Services to build a product or service
        which competes with the Services;
      </li>
      <li>use the Services to provide services to third parties;</li>
      <li>
        license, sell, rent, lease, transfer, assign, distribute, display,
        disclose, or otherwise commercially exploit, or otherwise make the
        Services available to any third party except the Authorised Users; or
      </li>
      <li>
        attempt to obtain, or assist third parties in obtaining, access to the
        Services, other than as provided under this Terms of Service.
      </li>
    </ol>
    <p>2.2 You shall not use the Services to:</p>
    <ol>
      <li>
        distribute or transmit to us any Viruses or Vulnerability and shall
        implement procedures in line with Good Industry Practice to prevent such
        distribution or transmission;
      </li>
      <li>
        store, access, publish, disseminate, distribute or transmit any material
        which:
        <ol type="a">
          <li>
            is unlawful, harmful, threatening, defamatory, obscene, infringing,
            harassing or racially or ethnically offensive;
          </li>
          <li>facilitates illegal activity;</li>
          <li>depicts sexually explicit images;</li>
          <li>promotes unlawful violence;</li>
          <li>
            is discriminatory based on race, gender, colour, religious belief,
            sexual orientation, disability; or
          </li>
          <li>
            is otherwise illegal or causes damage or injury to any person or
            property;
          </li>
        </ol>
      </li>
    </ol>
    <p>
      and we reserve the right, on no less than thirty (30) days’ prior written
      notice to you, such notice specifying the breach of this condition and
      requiring it to be remedied within the thirty (30) day period, to disable
      your access to the Services for the duration of time that the breach
      remains unremedied.
    </p>

    <h3>3. INTELLECTUAL PROPERTY RIGHTS</h3>
    <p>
      3.1 You acknowledge that all intellectual property rights in the Services
      anywhere in the world belong to us or our licensors, that rights in the
      Services are licensed (not sold) to you, and that you have no rights in,
      or to, the Services other than the right to use them in accordance with
      the terms of this Terms of Service.
    </p>
    <p>
      3.2 You acknowledge that you have no right to have access to any Services
      in source code form.
    </p>

    <h3>4. SUBSCRIPTION PURCHASE AND PAYMENT</h3>
    <p>
      4.1 Your Subscription will continue and automatically renew unless
      cancelled. You consent to our charging your payment method on a recurring
      basis without requiring your prior approval for each recurring charge,
      until such time as you cancel the applicable order. The length of your
      billing cycle is monthly.
    </p>
    <p>
      4.2 The Subscription fee, with or without a free trial period, applicable
      to you will be agreed with you and confirmed via email.
    </p>
    <p>
      4.3 All purchases are non-refundable. You can cancel your Subscription at
      any time by logging into your account. Your cancellation will take effect
      at the end of the current paid term. If you have any questions or are
      unsatisfied with our Services, please email us at
      <a href="mailto:legal@usecharter.io">legal@usecharter.io</a>.
    </p>
    <p>
      4.4 We may, from time to time, make changes to the Subscription fee
      applicable to you and will communicate any price changes to you in
      accordance with applicable law.
    </p>
    <p>4.5 We accept the following forms of payment:</p>
    <ul>
      <li>Visa</li>
      <li>Mastercard</li>
      <li>American Express</li>
    </ul>
    <p>
      4.6 You agree to provide current, complete, and accurate purchase and
      account information for all purchases made via the Services. You further
      agree to promptly update account and payment information, including email
      address, payment method, and payment card expiration date, so that we can
      complete your transactions and contact you as needed. In the event of a
      failed payment, you will have 72 hours to update your payment information
      after which time your account will be suspended until you update your
      payment information and a payment is made successfully. Any relevant tax,
      such as Value Added Tax or equivalent hereto, will be added to the price
      of purchases as deemed required by us. We may change prices at any time.
      All payments shall be in US dollars ($)(USD).
    </p>
    <p>
      4.7 You agree to pay all charges at the prices then in effect for your
      purchases and any applicable shipping fees, and you authorise us to charge
      your chosen payment provider for any such amounts upon placing your order.
      We reserve the right to correct any errors or mistakes in pricing, even if
      we have already requested or received payment.
    </p>
    <p>
      4.8 We reserve the right to refuse any order placed through the Services.
      We may, in our sole discretion, limit or cancel quantities purchased per
      person, per household, per entity or per order. These restrictions may
      include orders placed by or under the same customer account, the same
      payment method, and/or orders that use the same billing or shipping
      address. We reserve the right to limit or prohibit orders that, in our
      sole judgement, appear to be placed by dealers, resellers, or
      distributors.
    </p>

    <h3>5. USER GENERATED CONTRIBUTIONS</h3>
    <p>
      5.1 The Services does not offer users to submit or post content. We may
      provide you with the opportunity to create, submit, post, display,
      transmit, perform, publish, distribute, or broadcast content and materials
      to us or on the Services, including but not limited to text, writings,
      video, audio, photographs, graphics, comments, suggestions, or personal
      information or other material (collectively,
      <span class="font-black">Contributions'</span>'). Contributions may be
      viewable by other users of the Services and through third-party websites.
      As such, any Contributions you transmit may be treated in accordance with
      the Services' Privacy Policy. When you create or make available any
      Contributions, you thereby represent and warrant that:
    </p>
    <ol>
      <li>
        The creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your
        Contributions do not and will not infringe the proprietary rights,
        including but not limited to the copyright, patent, trademark, trade
        secret, or moral rights of any third party.
      </li>
      <li>
        You are the creator and owner of or have the necessary licences, rights,
        consents, releases, and permissions to use and to authorise us, the
        Services, and other users of the Services to use your Contributions in
        any manner contemplated by the Services and these Terms of Service.
      </li>
      <li>
        You have the written consent, release, and/or permission of each and
        every identifiable individual person in your Contributions to use the
        name or likeness of each and every such identifiable individual person
        to enable inclusion and use of your Contributions in any manner
        contemplated by the Services and these Terms of Service.
      </li>
      <li>Your Contributions are not false, inaccurate, or misleading.</li>
      <li>
        Your Contributions are not unsolicited or unauthorised advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation.
      </li>
      <li>
        Your Contributions are not obscene, lewd, lascivious, filthy, violent,
        harassing, libellous, slanderous, or otherwise objectionable (as
        determined by us).
      </li>
      <li>
        Your Contributions do not ridicule, mock, disparage, intimidate, or
        abuse anyone.
      </li>
      <li>
        Your Contributions are not used to harass or threaten (in the legal
        sense of those terms) any other person and to promote violence against a
        specific person or class of people.
      </li>
      <li>
        Your Contributions do not violate any applicable law, regulation, or
        rule.
      </li>
      <li>
        Your Contributions do not violate the privacy or publicity rights of any
        third party.
      </li>
      <li>
        Your Contributions do not violate any applicable law concerning child
        pornography, or otherwise intended to protect the health or well-being
        of minors.
      </li>
      <li>
        Your Contributions do not include any offensive comments that are
        connected to race, gender, colour, religious belief, sexual orientation,
        disability.
      </li>
      <li>
        Your Contributions do not otherwise violate, or link to material that
        violates, any provision of these Terms of Service, or any applicable law
        or regulation.
      </li>
    </ol>
    <p>
      Any use of the Services in violation of the foregoing violates these Terms
      of Service and may result in, among other things, termination or
      suspension of your rights to use the Services.
    </p>

    <h3>6. CONTRIBUTION LICENCE</h3>
    <p>
      6.1 By submitting suggestions or other feedback regarding the Services,
      you agree that we can use and share such feedback for any purpose without
      compensation to you.
    </p>
    <p>
      6.2 We do not assert any ownership over your Contributions. You retain
      full ownership of all of your Contributions and any intellectual property
      rights or other proprietary rights associated with your Contributions. We
      are not liable for any statements or representations in your Contributions
      provided by you in any area on the Services. You are solely responsible
      for your Contributions to the Services and you expressly agree to
      exonerate us from any and all responsibility and to refrain from any legal
      action against us regarding your Contributions.
    </p>

    <h3>7. MOBILE APPLICATION LICENCE</h3>
    <p>
      7.1 If you access the Services via the App, then we grant you a revocable,
      non-exclusive, non-transferable, limited right to install and use the App
      on wireless electronic devices owned or controlled by you, and to access
      and use the App on such devices strictly in accordance with the terms and
      conditions of this mobile application licence contained in these Terms of
      Service. You shall not:
    </p>
    <ol>
      <li>
        except as permitted by applicable law, decompile, reverse engineer,
        disassemble, attempt to derive the source code of, or decrypt the App;
      </li>
      <li>
        make any modification, adaptation, improvement, enhancement,
        translation, or derivative work from the App;
      </li>
      <li>
        violate any applicable laws, rules, or regulations in connection with
        your access or use of the App;
      </li>
      <li>
        remove, alter, or obscure any proprietary notice (including any notice
        of copyright or trademark) posted by us or the licensors of the App;
      </li>
      <li>
        use the App for any revenue-generating endeavour, commercial enterprise,
        or other purpose for which it is not designed or intended;
      </li>
      <li>
        make the App available over a network or other environment permitting
        access or use by multiple devices or users at the same time;
      </li>
      <li>
        use the App for creating a product, service, or software that is,
        directly or indirectly, competitive with or in any way a substitute for
        the App;
      </li>
      <li>
        use the App to send automated queries to any website or to send any
        unsolicited commercial email; or
      </li>
      <li>
        use any proprietary information or any of our interfaces or our other
        intellectual property in the design, development, manufacture,
        licensing, or distribution of any applications, accessories, or devices
        for use with the App.
      </li>
    </ol>
    <p>
      7.2 The following terms apply when you use the App obtained from either
      the Apple Store or Google Play (each an 'App Distributor') to access the
      Services:
    </p>
    <ol>
      <li>
        the licence granted to you for our App is limited to a non-transferable
        licence to use the application on a device that utilises the Apple iOS
        or Android operating systems, as applicable, and in accordance with the
        usage rules set forth in the applicable App Distributor's terms of
        service;
      </li>
      <li>
        we are responsible for providing any maintenance and support services
        with respect to the App as specified in the terms and conditions of this
        mobile application licence contained in these Terms of Service or as
        otherwise required under applicable law, and you acknowledge that each
        App Distributor has no obligation whatsoever to furnish any maintenance
        and support services with respect to the App;
      </li>
      <li>
        in the event of any failure of the App to conform to any applicable
        warranty, you may notify the applicable App Distributor, and the App
        Distributor, in accordance with its terms and policies, may refund the
        purchase price, if any, paid for the App, and to the maximum extent
        permitted by applicable law, the App Distributor will have no other
        warranty obligation whatsoever with respect to the App;
      </li>
      <li>
        you represent and warrant that (i) you are not located in a country that
        is subject to a US government embargo, or that has been designated by
        the US government as a 'terrorist supporting' country and (ii) you are
        not listed on any US government list of prohibited or restricted
        parties;
      </li>
      <li>
        you must comply with applicable third-party terms of agreement when
        using the App, e.g. if you have a VoIP application, then you must not be
        in violation of their wireless data service agreement when using the
        App; and
      </li>
      <li>
        you acknowledge and agree that the App Distributors are third-party
        beneficiaries of the terms and conditions in this mobile application
        licence contained in this Terms of Service, and that each App
        Distributor will have the right (and will be deemed to have accepted the
        right) to enforce the terms and conditions in this mobile application
        licence contained in these Terms of Service against you as a third-party
        beneficiary thereof.
      </li>
    </ol>

    <h3>8. SOCIAL MEDIA</h3>
    <p>
      8.1 As part of the functionality of the Services, you may link your
      account with online accounts you have with third-party service providers
      (each such account, a
      <span class="font-black">'Third-Party Account'</span>) by either:
    </p>
    <ol>
      <li>
        providing your Third-Party Account login information through the
        Services; or
      </li>
      <li>
        allowing us to access your Third-Party Account, as is permitted under
        the applicable terms and conditions that govern your use of each
        Third-Party Account.
      </li>
    </ol>
    <p>
      8.2 You represent and warrant that you are entitled to disclose your
      Third-Party Account login information to us and/or grant us access to your
      Third-Party Account, without breach by you of any of the terms and
      conditions that govern your use of the applicable Third-Party Account, and
      without obligating us to pay any fees or making us subject to any usage
      limitations imposed by the third-party service provider of the Third-Party
      Account. By granting us access to any Third-Party Accounts, you understand
      that:
    </p>
    <ol>
      <li>
        we may access, make available, and store (if applicable) any content
        that you have provided to and stored in your Third-Party Account (the
        (<span class="font-black">'Social Network Content'</span>) so that it is
        available on and through the Services via your account, including
        without limitation any friend lists; and
      </li>
      <li>
        we may submit to and receive from your Third-Party Account additional
        information to the extent you are notified when you link your account
        with the Third-Party Account.
      </li>
    </ol>
    <p>
      8.3 Depending on the Third-Party Accounts you choose and subject to the
      privacy settings that you have set in such Third-Party Accounts,
      personally identifiable information that you post to your Third-Party
      Accounts may be available on and through your account on the Services.
    </p>
    <p>
      8.4 Please note that if a Third-Party Account or associated service
      becomes unavailable or our access to such Third-Party Account is
      terminated by the third-party service provider, the Social Network Content
      may no longer be available on and through the Services.
    </p>
    <p>
      8.5 You will have the ability to disable the connection between your
      account on the Services and your Third-Party Accounts at any time.
    </p>
    <p>
      8.6 Please note that your relationship with the third-party service
      providers associated with your third-party accounts is governed solely by
      your agreement(s) with such third-party service providers.
    </p>
    <p>
      8.7 We make no effort to review any Social Network Content for any
      purpose, including but not limited to, for accuracy, legality, or
      non-infringement, and we are not responsible for any Social Network
      Content.
    </p>
    <p>
      8.8 You acknowledge and agree that we may access your email address book
      associated with a Third-Party Account and your contacts list stored on
      your mobile device or tablet computer solely for purposes of identifying
      and informing you of those contacts who have also registered to use the
      Services.
    </p>
    <p>
      8.9 You can deactivate the connection between the Services and your
      Third-Party Account by contacting us using the contact information below
      or through your account settings (if applicable). We will attempt to
      delete any information stored on our servers that was obtained through
      such a Third-Party Account, except the username and profile picture that
      become associated with your account.
    </p>

    <h3>9. THIRD-PARTY WEBSITES AND CONTENT</h3>
    <p>
      9.1 The Services may contain (or you may be sent via the Site or App)
      links to other websites (<span class="font-black"
        >'Third-Party Websites'</span
      >) as well as articles, photographs, text, graphics, pictures, designs,
      music, sound, video, information, applications, software, and other
      content or items belonging to or originating from third parties (<span
        class="font-black"
        >'Third-Party Content'</span
      >).
    </p>
    <p>
      9.2 Such Third­-Party Websites and Third-Party Content are not
      investigated, monitored, or checked for accuracy, appropriateness, or
      completeness by us, and we are not responsible for any Third-Party
      Websites accessed through the Services or any Third-Party Content posted
      on, available through, or installed from the Services, including the
      content, accuracy, offensiveness, opinions, reliability, privacy
      practices, or other policies of or contained in the Third-Party Websites
      or the Third-Party Content. Inclusion of, linking to, or permitting the
      use or installation of any Third-Party Websites or any Third-Party Content
      does not imply approval or endorsement thereof by us.
    </p>
    <p>
      9.3 If you decide to leave the Services and access the Third-Party
      Websites or to use or install any Third-Party Content, you do so at your
      own risk, and you should be aware these Terms of Service no longer govern.
      You should review the applicable terms and policies, including privacy and
      data gathering practices, of any website to which you navigate from the
      Services or relating to any applications you use or install from the
      Services.
    </p>
    <p>
      9.4 Any purchases you make through Third-Party Websites will be through
      other websites and from other companies, and we take no responsibility
      whatsoever in relation to such purchases which are exclusively between you
      and the applicable third party. You agree and acknowledge that we do not
      endorse the products or services offered on Third-Party Websites and you
      shall hold us blameless from any harm caused by your purchase of such
      products or services. Additionally, you shall hold us blameless from any
      losses sustained by you or harm caused to you relating to or resulting in
      any way from any Third-Party Content or any contact with Third-Party
      Websites.
    </p>

    <h3>10. MODIFICATIONS AND INTERRUPTIONS</h3>
    <p>
      10.1 We reserve the right to change, modify, or remove the contents of the
      Services at any time or for any reason at our sole discretion without
      notice. However, we have no obligation to update any information on our
      Services. We will not be liable to you or any third party for any
      modification, price change, suspension, or discontinuance of the Services.
    </p>
    <p>
      10.2 We cannot guarantee the Services will be available at all times. We
      may experience hardware, software, or other problems or need to perform
      maintenance related to the Services, resulting in interruptions, delays,
      or errors. We reserve the right to change, revise, update, suspend,
      discontinue, or otherwise modify the Services at any time or for any
      reason without notice to you. You agree that we have no liability
      whatsoever for any loss, damage, or inconvenience caused by your inability
      to access or use the Services during any downtime or discontinuance of the
      Services. Nothing in these Terms of Service will be construed to obligate
      us to maintain and support the Services or to supply any corrections,
      updates, or releases in connection therewith.
    </p>

    <h3>11. CORRECTIONS</h3>
    <p>
      11.1 There may be information on the Services that contains typographical
      errors, inaccuracies, or omissions, including descriptions, pricing,
      availability, and various other information. We reserve the right to
      correct any errors, inaccuracies, or omissions and to change or update the
      information on the Services at any time, without prior notice.
    </p>

    <h3>12. LIMITATION OF LIABILITY</h3>
    <p>
      12.1 You accept responsibility for the selection of the Services to
      achieve your intended results and acknowledge that the Services have not
      been developed or designed to meet or support any individual requirements
      you have, including any particular cybersecurity requirements you might be
      subject to, or any regulated activity that you may be engaged in,
      including the provision of an online intermediation service, an online
      search engine or service that facilitates online interaction between users
      (such as, but not limited to, a social media platform) (each a Regulated
      Activity). If you use the Services for any Regulated Activity you agree to
      comply with any requirements that apply to such Regulated Activity from
      time to time (including in any jurisdiction in which you operate or where
      the Regulated Activity is undertaken) and you shall defend, indemnify and
      hold us harmless against any loss or damage (including regulatory fines or
      penalties) costs (including legal fees) and expenses which we may suffer
      or incur as a result of your breach of this clause 12.1.
    </p>
    <p>
      12.2 We only supply the Services for internal use by your business, and
      you agree not to use the Services for any resale purposes.
    </p>
    <p>
      12.3 We shall not in any circumstances whatever be liable to you, whether
      in contract, tort (including negligence), breach of statutory duty, or
      otherwise, arising under or in connection with the Terms of Service for:
    </p>
    <ol>
      <li>loss of profits, sales, business, or revenue;</li>
      <li>business interruption;</li>
      <li>loss of anticipated savings;</li>
      <li>wasted expenditure;</li>
      <li>loss or corruption of data or information;</li>
      <li>
        loss of business opportunity, goodwill or reputation, where any of the
        losses set out in <span class="italic">Condition 12.3(a)</span> to
        <span class="italic">Condition 12.3(f)</span> are direct or indirect; or
      </li>
      <li>
        any special, indirect or consequential loss, damage, charges or
        expenses.
      </li>
    </ol>
    <p>
      12.4 Other than the losses set out in
      <span class="italic">Condition 12.3</span> (for which we are not liable),
      our maximum aggregate liability under or in connection with this Terms of
      Service whether in contract, tort (including negligence) or otherwise,
      shall in all circumstances be limited to the amount paid, if any, by you
      to us during the six (6) month period prior to any cause of action
      arising. This maximum cap does not apply to
      <span class="italic">Condition 12.5</span>.
    </p>
    <p>
      12.5 Nothing in this Terms of Service shall limit or exclude our liability
      for:
    </p>
    <ol type="a">
      <li>death or personal injury resulting from our negligence;</li>
      <li>fraud or fraudulent misrepresentation; or</li>
      <li>
        any other liability that cannot be excluded or limited by English law.
      </li>
    </ol>
    <p>
      12.6 This Terms of Service sets out the full extent of our obligations and
      liabilities in respect of the supply of the Services. Except as expressly
      stated in this Terms of Service, there are no conditions, warranties,
      representations or other terms, express or implied, that are binding on
      us. Any condition, warranty, representation or other term concerning the
      supply of the Services which might otherwise be implied into, or
      incorporated in, this Terms of Service whether by statute, common law or
      otherwise, is excluded to the fullest extent permitted by law.
    </p>

    <h3>13. DISCLAIMER</h3>
    <p>
      13.1 The Services are provided on an as-is and as-available basis. you
      agree that your use of the Services will be at your sole risk. To the
      fullest extent permitted by law, we disclaim all warranties, express or
      implied, in connection with the Services and your use thereof, including,
      without limitation, the implied warranties of merchantability, fitness for
      a particular purpose, and non­infringement we make no warranties or
      representations about the accuracy or completeness of the Services'
      content or the content of any websites or mobile applications linked to
      the Services and we will assume no liability or responsibility for any:
    </p>
    <ol>
      <li>errors, mistakes, or inaccuracies of content and materials;</li>
      <li>
        personal injury or property damage, of any nature whatsoever, resulting
        from your access to and use of the Services;
      </li>
      <li>
        any unauthorised access to or use of our secure servers and/or any and
        all personal information and/or financial information stored therein;
      </li>
      <li>
        any interruption or cessation of transmission to or from the services;
      </li>
      <li>
        any bugs, viruses, trojan horses, or the like which may be transmitted
        to or through the services by any third party, and/or;
      </li>
      <li>
        any errors or omissions in any content and materials or for any loss or
        damage of any kind incurred as a result of the use of any content
        posted, transmitted, or otherwise made available via the services.
      </li>
    </ol>
    <p>
      13.2 We do not warrant, endorse, guarantee, or assume responsibility for
      any product or service advertised or offered by a third party through the
      services, any hyperlinked website, or any website or mobile application
      featured in any banner or other advertising, and we will not be a party to
      or in any way be responsible for monitoring any transaction between you
      and any third-party providers of products or services.
    </p>
    <p>
      13.3 As with the purchase of a product or service through any medium or in
      any environment, you should use your best judgment and exercise caution
      where appropriate.
    </p>

    <h3>14. INDEMNIFICATION</h3>
    <p>
      14.1 You agree to defend, indemnify, and hold us harmless, including our
      subsidiaries, affiliates, and all of our respective officers, agents,
      partners, and employees, from and against any loss, damage, liability,
      claim, or demand, including reasonable legal fees and expenses, made by
      any third party due to or arising out of:
    </p>
    <ol>
      <li>use of the Services;</li>
      <li>breach of these Terms of Service;</li>
      <li>
        any breach of your representations and warranties set forth in these
        Terms of Service;
      </li>
      <li>
        your violation of the rights of a third party, including but not limited
        to intellectual property rights; or
      </li>
      <li>
        any overt harmful act toward any other user of the Services with whom
        you connected via the Services.
      </li>
    </ol>
    <p>
      14.2 Notwithstanding the foregoing, we reserve the right, at your expense,
      to assume the exclusive defence and control of any matter for which you
      are required to indemnify us, and you agree to cooperate, at your expense,
      with our defence of such claims. We will use reasonable efforts to notify
      you of any such claim, action, or proceeding which is subject to this
      indemnification upon becoming aware of it.
    </p>

    <h3>15. TERMINATION</h3>
    <p>
      15.1 We may terminate your Subscription immediately by written notice to
      you if you commit a material or persistent breach of this Terms of Service
      which you fail to remedy (if remediable) within 14 days after the service
      of written notice requiring you to do so.
    </p>
    <p>15.2 On termination for any reason:</p>
    <ol>
      <li>
        all rights granted to you under this Terms of Service shall cease;
      </li>
      <li>
        you must immediately cease all activities authorised by this Terms of
        Service; and
      </li>
      <li>
        you must immediately and permanently delete or disable interfaces to the
        Services from all computer equipment and/or mobile devices in your
        possession, and immediately destroy, delete or return to us (at our
        option) all copies of the Services then in your possession, custody or
        control and, in the case of destruction or deletion, certify to us in
        writing that you have done so.
      </li>
    </ol>

    <h3>16. COMMUNICATIONS BETWEEN US</h3>
    <p>
      16.1 We may update this Terms of Service at any time on notice to you in
      accordance with this <span class="italic">Condition 16</span>. Your
      continued use of the Services following the deemed receipt and service of
      the notice under <span class="italic">Condition 16.3</span> shall
      constitute your acceptance of the Terms of Service, as varied. If you do
      not wish to accept the Terms of Service (as varied) you must immediately
      stop using and accessing the Services on the deemed receipt and service of
      the notice.
    </p>
    <p>
      16.2 If we have to contact you, we will do so by email or by pre-paid post
      to the address you provided in accordance with your registration of the
      Services.
    </p>
    <p>16.3 Any notice:</p>
    <ol>
      <li>
        given by us to you will be deemed received and properly served 24 hours
        after it is first posted on our website, 24 hours after an email is
        sent, or three days after the date of posting of any letter; and
      </li>
      <li>
        given by you to us will be deemed received and properly served 24 hours
        after an email is sent, or three days after the date of posting of any
        letter.
      </li>
    </ol>
    <p>
      16.4 In proving the service of any notice, it will be sufficient to prove,
      in the case of posting on our website, that the website was generally
      accessible to the public for a period of 24 hours after the first posting
      of the notice; in the case of a letter, that such letter was properly
      addressed, stamped and placed in the post to the address of the recipient
      given for these purposes; and, in the case of an email, that such email
      was sent to the email address of the recipient given for these purposes.
    </p>

    <h3>17. EVENTS OUTSIDE OUR CONTROL</h3>
    <p>
      17.1 We will not be liable or responsible for any failure to perform, or
      delay in performance of, any of our obligations under this Terms of
      Service that is caused by an Event Outside Our Control. An Event Outside
      Our Control is defined below in
      <span class="italic">Condition 17.2</span>.
    </p>
    <p>
      17.2 An Event Outside Our Control means any act or event beyond our
      reasonable control, including without limitation failure of public or
      private telecommunications networks.
    </p>
    <p>
      17.3 If an Event Outside Our Control takes place that affects the
      performance of our obligations under this Terms of Service:
    </p>
    <ol>
      <li>
        our obligations under this Terms of Service will be suspended and the
        time for performance of our obligations will be extended for the
        duration of the Event Outside Our Control; and
      </li>
      <li>
        we will use our reasonable endeavours to find a solution by which our
        obligations under this Terms of Service may be performed despite the
        Event Outside Our Control.
      </li>
    </ol>

    <h3>18. HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
    <p>
      18.1 Under data protection legislation, we are required to provide you
      with certain information about who we are, how we process the personal
      data of those individuals who use the Services and for what purposes and
      those individuals’ rights in relation to their personal data and how to
      exercise them. This information is provided in
      <a :href="privacy_url">{{ privacy_url }}</a>
      (<span class="font-black">‘Privacy Policy’</span>) and it is important
      that you read that information.
    </p>

    <h3>19. OTHER IMPORTANT TERMS</h3>
    <p>
      19.1 We may transfer our rights and obligations under this Terms of
      Service to another organisation, but this will not affect your rights or
      our obligations under this Terms of Service.
    </p>
    <p>
      19.2 You may only transfer your rights or your obligations under this
      Terms of Service to another person if we agree in writing.
    </p>
    <p>
      19.3 This Terms of Service and any document expressly referred to in it
      constitutes the entire agreement between us and supersedes and
      extinguishes all previous and contemporaneous agreements, promises,
      assurances and understandings between us, whether written or oral,
      relating to its subject matter.
    </p>
    <p>
      19.4 You acknowledge that in entering into this Terms of Service you do
      not rely on and shall have no remedies in respect of any statement,
      representation, assurance or warranty (whether made innocently or
      negligently) that is not set out in this Terms of Service or any document
      expressly referred to in it.
    </p>
    <p>
      19.5 You agree that you shall have no claim for innocent or negligent
      misrepresentation or negligent misstatement based on any statement in this
      Terms of Service or any document expressly referred to in it.
    </p>
    <p>
      19.6 A waiver of any right or remedy is only effective if given in writing
      and shall not be deemed a waiver of any subsequent right or remedy.
    </p>
    <p>
      19.7 A delay or failure to exercise, or the single or partial exercise of,
      any right or remedy shall not waive that or any other right or remedy, nor
      shall it prevent or restrict the further exercise of that or any other
      right or remedy.
    </p>
    <p>
      19.8 Each of the conditions of this Terms of Service operates separately.
      If any court or competent authority decides that any of them are unlawful
      or unenforceable, the remaining conditions will remain in full force and
      effect.
    </p>
    <p>
      19.9 This Terms of Service, its subject matter and its formation (and any
      non-contractual disputes or claims) are governed by English law. We both
      irrevocably agree to the exclusive jurisdiction of the courts of England
      and Wales.
    </p>
  </div>
</template>

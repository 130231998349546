<script setup lang="ts">
import { PropType, ref } from "vue";

import DialogBody from "@/components/dialog-body.vue";
import DialogTitlebar from "@/components/dialog-titlebar.vue";
import StyledButton from "@/components/form/styled-button.vue";
import { Campaign } from "@/dtos/campaign.ts";
import { ButtonType } from "@/enums/button-type.ts";
import { api } from "@/helpers/api.ts";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
});
const workspaceStore = useWorkspaceStore();

const submitting = ref<boolean>(false);

const cancel = () => {
  emit("close");
};
const submit = () => {
  if (submitting.value) {
    return;
  }
  submitting.value = true;

  workspaceStore.workspace!.campaigns.forEach((campaign_: Campaign) => {
    if (campaign_.id === props.campaign.id) {
      campaign_.shared_budget_id = undefined;
    }
  });
  api
    .put("workspace/move-campaign-into-group", {
      campaign_id: props.campaign.id,
      shared_budget_id: null,
    })
    .then(() => {
      workspaceStore.reload(workspaceStore.workspace!);
      submitting.value = false;
      emit("close");
    });
};
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('close')"
      title="Remove campaign from shared budget"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex w-full max-w-[800px] flex-col space-y-5">
        <span>Are you sure?</span>
        <div class="flex w-full flex-row items-start space-x-3 pt-4">
          <styled-button
            :type="ButtonType.DESTRUCTIVE"
            :submitting="submitting"
            @click="submit"
            >Confirm</styled-button
          >
          <styled-button
            :type="ButtonType.REGULAR"
            :submitting="submitting"
            @click="cancel"
            >Cancel</styled-button
          >
        </div>
      </div>
    </dialog-body>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";

import SharedBudget from "@/app/workspace/campaigns/shared-budgets/shared-budget.vue";
import TimeframeSelect from "@/app/workspace/campaigns/timeframe-select.vue";
import Tabs from "@/app/workspace/tabs.vue";
import { useWorkspaceStore } from "@/stores/workspace.ts";

const workspaceStore = useWorkspaceStore();
const route = useRoute();
// const router = useRouter();

// const loaded = () => {
// if (!workspaceStore.workspace?.ad_channels.length) {
// router.replace({
//   name: "ad-channels",
//   params: {
//     params: route.params.workspace,
//   },
//   query: {
//     setup: "true",
//   },
// });
// return;
// } else if (!workspaceStore.workspace?.storefronts.length) {
//   router.replace({
//     name: "storefronts",
//     params: {
//       params: route.params.workspace,
//     },
//     query: {
//       setup: "true",
//     },
//   });
//   return;
// }
// };
//
// if (workspaceStore.workspace) {
//   loaded();
// } else {
//   watchOnce(() => workspaceStore.workspace, loaded);
// }
</script>
<template>
  <router-view v-if="route.name !== 'campaigns'"></router-view>
  <div class="w-full" v-else>
    <tabs class="mb-7 mt-0">
      <div
        class="relative top-1 flex select-none items-center space-x-12 text-base text-zinc-500"
      >
        <timeframe-select></timeframe-select>
      </div>
    </tabs>
    <div
      class="flex flex-col space-y-2"
      v-if="
        workspaceStore.groupedCampaigns &&
        workspaceStore.groupedCampaigns.length >= 1
      "
    >
      <shared-budget
        v-for="group_of_campaigns in workspaceStore.groupedCampaigns"
        :key="group_of_campaigns.key"
        :group-of-campaigns="group_of_campaigns"
      ></shared-budget>
    </div>
    <div class="h-16"></div>
  </div>
</template>

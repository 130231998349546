import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { RouteLocationNormalized, useRoute } from "vue-router";

import { Account } from "@/dtos/account.ts";
import { AdChannel } from "@/dtos/ad-channel.ts";
import { Campaign, CampaignRaw } from "@/dtos/campaign.ts";
import { GroupedCampaigns } from "@/dtos/grouped-campaigns.ts";
import {
  SerializableMatch,
  SerializableRoute,
} from "@/dtos/serializable-route.ts";
import { SharedBudget, SharedBudgetRaw } from "@/dtos/shared-budget.ts";
import { Workspace, WorkspaceRaw } from "@/dtos/workspace.ts";
import { WorkspaceRole, WorkspaceRoleRaw } from "@/dtos/workspace-role.ts";
import { WorkspacesInAccount } from "@/dtos/workspaces-in-account.ts";
import { AccountRoles } from "@/enums/account-roles.ts";
import { CampaignsTimeframe } from "@/enums/campaigns-timeframe.ts";
import { api } from "@/helpers/api.ts";
import { nullsToUndefined } from "@/helpers/nulls-to-undefined.ts";
import { waitFor } from "@/helpers/wait-for.ts";
import { useAccountStore } from "@/stores/account.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useCampaignStore } from "@/stores/campaign.ts";
import { useCurrencyStore } from "@/stores/currency.ts";
import { useSharedBudgetStore } from "@/stores/shared-budget.ts";

export const useWorkspaceStore = defineStore("workspace", () => {
  const route = useRoute();
  const accountStore = useAccountStore();
  const authStore = useAuthStore();
  const currencyStore = useCurrencyStore();
  const campaignStore = useCampaignStore();
  const sharedBudgetStore = useSharedBudgetStore();

  const convertRawWorkspaceRole = (
    workspace_role_raw: WorkspaceRoleRaw,
  ): WorkspaceRole => {
    return nullsToUndefined({
      ...workspace_role_raw,
      user: authStore.convertRawUser(workspace_role_raw.user),
      inviter: workspace_role_raw.inviter
        ? authStore.convertRawUser(workspace_role_raw.inviter)
        : undefined,
    }) as WorkspaceRole;
  };

  const convertRawWorkspace = (workspace_raw: WorkspaceRaw): Workspace => {
    return {
      ...workspace_raw,
      account: accountStore.find(workspace_raw.account_id)!,
      currency: currencyStore.find(workspace_raw.currency_id)!,
      roles: workspace_raw.roles.map((role: WorkspaceRoleRaw) =>
        convertRawWorkspaceRole(role),
      ),
      // account_roles: workspace_raw.account_roles.map((role: AccountRoleRaw) =>
      //   accountStore.convertRawAccountRole(role),
      // ),
      campaigns: workspace_raw.campaigns.map((campaign: CampaignRaw) =>
        campaignStore.convertRawCampaign(campaign),
      ),
      shared_budgets: workspace_raw.shared_budgets.map(
        (shared_budget: SharedBudgetRaw) =>
          sharedBudgetStore.convertRawSharedBudget(shared_budget),
      ),
    } as Workspace;
  };

  const workspaces = ref<Workspace[] | undefined>(undefined);
  const setWorkspaces = (newWorkspaces: WorkspaceRaw[]) => {
    clearWorkspaces();
    workspaces.value = newWorkspaces
      .map((raw_workspace: WorkspaceRaw) => convertRawWorkspace(raw_workspace))
      .sort((a, b) => {
        if (!a.name || !b.name) {
          return 0;
        }
        return a.name.localeCompare(b.name);
      });
    workspaces.value?.forEach((workspace_) => {
      openWebSockets(workspace_);
    });
  };
  const clearWorkspaces = () => {
    workspaces.value?.forEach((workspace_) => {
      closeWebSockets(workspace_);
    });
    workspaces.value = undefined;
  };
  const latestWorkspace = () => {
    if (!workspaces.value || !workspaces.value.length) {
      return undefined;
    }
    return workspaces.value[0]; // todo
  };
  // const createWorkspace = async (
  //   account_id: number,
  //   name?: string | undefined,
  // ): Promise<Workspace> => {
  //   const data = (await api.post("workspace", {
  //     account_id,
  //     name,
  //   })) as NewWorkspace;
  //   const init = data.init;
  //   const workspace_id = data.workspace_id;
  //   authStore.load(init);
  //   for (let i = 0; i < workspaces.value!.length; ++i) {
  //     if (workspaces.value![i].id === workspace_id) {
  //       return workspaces.value![i];
  //     }
  //   }
  //   throw new Error("New workspace not in the store");
  // };
  // const insertWorkspace = (createWorkspace) => {
  //   for (let i = 0; i < workspaces.value.length; ++i) {
  //     if (workspaces.value[i].id === createWorkspace.id) {
  //       Object.assign(workspaces.value[i], createWorkspace)
  //       workspaces.value[i].touch = workspaces.value[i].touch ? workspaces.value[i].touch + 1 : 1
  //       workspaces.value[i].name = 'test'
  //       return
  //     }
  //   }
  //   workspaces.value.push(createWorkspace)
  // }

  const mergeWorkspaceRaw = (workspace: WorkspaceRaw) => {
    mergeWorkspace(convertRawWorkspace(workspace));
  };
  const mergeWorkspace = (workspace: Workspace) => {
    for (let i = 0; i < workspaces.value!.length; ++i) {
      if (workspaces.value![i].id === workspace.id) {
        Object.assign(workspaces.value![i], workspace);
        return;
      }
    }
    workspaces.value!.push(workspace);
  };
  const workspacesInAccounts = computed<WorkspacesInAccount[] | undefined>(
    () => {
      if (!workspaces.value) {
        return undefined;
      }
      const workspacesInAccounts: WorkspacesInAccount[] = [];

      workspaces.value.forEach((workspace_: Workspace) => {
        // add to object
        let added_to_existing_account = false;
        for (let i = 0; i < workspacesInAccounts.length; ++i) {
          if (workspacesInAccounts[i].account.id === workspace_.account.id) {
            workspacesInAccounts[i].workspaces.push(workspace_);
            added_to_existing_account = true;
            break;
          }
        }
        if (!added_to_existing_account) {
          workspacesInAccounts.push({
            account: workspace_.account,
            account_name: workspace_.account_name,
            workspaces: [workspace_],
          });
        }
      });
      return (
        workspacesInAccounts
          // sort by name
          .sort((a, b) => {
            if (!a.account_name || !b.account_name) {
              return 0;
            }
            return a.account_name.localeCompare(b.account_name);
          })
          // sort by if owner
          .sort((a: WorkspacesInAccount, b: WorkspacesInAccount) => {
            const account_a: Account | undefined = a.account;
            const account_b: Account | undefined = b.account;
            if (!account_a && account_b) {
              return 1;
            } else if (account_a && !account_b) {
              return -1;
            } else if (!account_a && !account_b) {
              return 0;
            }
            const is_owner_of_a = accountStore.isOwner(
              authStore.user!.id,
              account_a!.roles,
            );
            const is_owner_of_b = accountStore.isOwner(
              authStore.user!.id,
              account_b!.roles,
            );
            if (!is_owner_of_a && is_owner_of_b) {
              return 1;
            } else if (is_owner_of_a && !is_owner_of_b) {
              return -1;
            }
            return 0;
          })
      );
    },
  );

  const acceptedWorkspacesInAccounts = computed<
    WorkspacesInAccount[] | undefined
  >(() => {
    if (!workspacesInAccounts.value) {
      return undefined;
    }
    const workspacesInAccounts_: WorkspacesInAccount[] = [];

    for (let i = 0; i < workspacesInAccounts.value.length; ++i) {
      let accountAdded: boolean = false;
      for (
        let ii = 0;
        ii < workspacesInAccounts.value[i].workspaces.length;
        ++ii
      ) {
        if (isAccepted(workspacesInAccounts.value[i].workspaces[ii])) {
          if (!accountAdded) {
            workspacesInAccounts_.push({
              ...workspacesInAccounts.value[i],
              workspaces: [],
            });
            accountAdded = true;
          }
          workspacesInAccounts_[
            workspacesInAccounts_.length - 1
          ].workspaces.push(workspacesInAccounts.value[i].workspaces[ii]);
        }
      }
    }
    return workspacesInAccounts_;
  });
  const pendingWorkspacesInAccounts = computed<
    WorkspacesInAccount[] | undefined
  >(() => {
    if (!workspacesInAccounts.value) {
      return undefined;
    }
    const workspacesInAccounts_: WorkspacesInAccount[] = [];

    for (let i = 0; i < workspacesInAccounts.value.length; ++i) {
      let accountAdded: boolean = false;
      for (
        let ii = 0;
        ii < workspacesInAccounts.value[i].workspaces.length;
        ++ii
      ) {
        if (isPending(workspacesInAccounts.value[i].workspaces[ii])) {
          if (!accountAdded) {
            workspacesInAccounts_.push({
              ...workspacesInAccounts.value[i],
              workspaces: [],
            });
            accountAdded = true;
          }
          workspacesInAccounts_[
            workspacesInAccounts_.length - 1
          ].workspaces.push(workspacesInAccounts.value[i].workspaces[ii]);
        }
      }
    }
    return workspacesInAccounts_;
  });

  const workspaceById = (id: number) => {
    if (!workspaces.value) {
      return undefined;
    }
    for (let i = 0; i < workspaces.value.length; ++i) {
      if (workspaces.value[i].id === id) {
        return workspaces.value[i];
      }
    }
  };

  const landing_workspace = ref<Workspace | undefined>(undefined);

  const loadLandingWorkspaceIfNecessary = () => {
    if (landing_workspace.value === undefined && route.name === "home") {
      api.get("landing/workspace").then((data: unknown) => {
        waitFor(() => currencyStore.currencies).then(() => {
          landing_workspace.value = convertRawWorkspace(data as WorkspaceRaw);
        });
      });
    }
  };
  loadLandingWorkspaceIfNecessary();
  watch(() => route.name, loadLandingWorkspaceIfNecessary);

  // current workspace based on route
  const workspace = computed<Workspace | undefined>(() => {
    if (route.name === "home") {
      return landing_workspace.value;
    }
    if (!workspaces.value) {
      return undefined;
    }
    for (let i = 0; i < workspaces.value.length; ++i) {
      if (parseInt(<string>route.params.workspace) === workspaces.value[i].id) {
        return workspaces.value[i];
      }
    }
    return undefined;
  });

  const workspaceChanged = computed(() => workspace);
  const workspacesLoaded = computed(() => workspaces);

  // campaigns

  const mergeCampaign = (campaign: Campaign, workspace: Workspace) => {
    for (let i = 0; i < workspace.campaigns.length; ++i) {
      if (workspace.campaigns[i].id === campaign.id) {
        Object.assign(workspace.campaigns[i], campaign);
        return;
      }
    }
    workspace.campaigns.push(campaign);
  };

  const mergeRawCampaign = (
    campaign_raw: CampaignRaw,
    workspace: Workspace,
  ) => {
    mergeCampaign(campaignStore.convertRawCampaign(campaign_raw), workspace);
  };

  const mergeRawCampaigns = (
    raw_campaigns: CampaignRaw[],
    workspace: Workspace,
  ) => {
    // todo: remove redundant campaigns

    raw_campaigns.forEach((campaign_raw: CampaignRaw) => {
      mergeRawCampaign(campaign_raw, workspace);
    });
  };

  const removeCampaign = (campaign_id: number, workspace_: Workspace) => {
    api.delete(`campaign?id=${campaign_id}`);
    for (let i = 0; i < workspace_.campaigns.length; ++i) {
      if (workspace_.campaigns[i].id === campaign_id) {
        workspace_.campaigns = workspace_.campaigns.filter(
          (campaign) => campaign.id !== campaign_id,
        );
        return;
      }
    }
  };
  // shared_budgets

  const mergeSharedBudget = (
    shared_budget: SharedBudget,
    workspace: Workspace,
  ) => {
    for (let i = 0; i < workspace.shared_budgets.length; ++i) {
      if (workspace.shared_budgets[i].id === shared_budget.id) {
        Object.assign(workspace.shared_budgets[i], shared_budget);
        return;
      }
    }
    workspace.shared_budgets.push(shared_budget);
  };

  const mergeRawSharedBudget = (
    shared_budget_raw: SharedBudgetRaw,
    workspace: Workspace,
  ) => {
    mergeSharedBudget(
      sharedBudgetStore.convertRawSharedBudget(shared_budget_raw),
      workspace,
    );
  };

  const mergeRawSharedBudgets = (
    raw_shared_budgets: SharedBudgetRaw[],
    workspace: Workspace,
  ) => {
    // todo: remove redundant shared_budgets?

    raw_shared_budgets.forEach((shared_budget_raw: SharedBudgetRaw) => {
      mergeRawSharedBudget(shared_budget_raw, workspace);
    });
  };

  const removeSharedBudget = async (
    shared_budget_id: number,
    workspace_: Workspace,
  ) => {
    for (let i = 0; i < workspace_.shared_budgets.length; ++i) {
      if (workspace_.shared_budgets[i].id === shared_budget_id) {
        workspace_.shared_budgets = workspace_.shared_budgets.filter(
          (shared_budget) => shared_budget.id !== shared_budget_id,
        );
        break;
      }
    }
    await api.delete(
      `workspace/shared-budget?shared_budget_id=${shared_budget_id}`,
    );
  };

  // ad channels

  const mergeAdChannel = (ad_channel: AdChannel, workspace: Workspace) => {
    for (let i = 0; i < workspace.ad_channels.length; ++i) {
      if (workspace.ad_channels[i].id === ad_channel.id) {
        Object.assign(workspace.ad_channels[i], ad_channel);
        return;
      }
    }
    workspace.ad_channels.push(ad_channel);
  };

  const mergeAdChannels = (ad_channels: AdChannel[], workspace: Workspace) => {
    // todo: remove redundant ad_channels

    ad_channels.forEach((ad_channel: AdChannel) => {
      mergeAdChannel(ad_channel, workspace);
    });
  };

  const removeAdChannel = (ad_channel_id: number, workspace_: Workspace) => {
    api.delete(`ad-channel?id=${ad_channel_id}`);
    for (let i = 0; i < workspace_.ad_channels.length; ++i) {
      if (workspace_.ad_channels[i].id === ad_channel_id) {
        workspace_.ad_channels = workspace_.ad_channels.filter(
          (ad_channel) => ad_channel.id !== ad_channel_id,
        );
        return;
      }
    }
  };

  // websockets

  const openWebSockets = (workspace_: Workspace) => {
    window.Echo.private(`workspace.${workspace_.id}`)
      .listen(".workspace.reload", () => {
        reload(workspace_).then(() => true);
      })
      .listen(".campaign.created", () => {
        reload(workspace_).then(() => true);
      });
  };
  const closeWebSockets = (workspace_: Workspace) => {
    window.Echo.leave(`workspace.${workspace_.id}`);
  };

  // permissions

  const grant = (
    permissions: string[],
    user_id: number,
    workspace_roles: WorkspaceRole[],
  ) => {
    if (!workspace_roles) {
      return false;
    }
    for (let i = 0; i < workspace_roles.length; ++i) {
      if (
        workspace_roles[i].user.id === user_id &&
        permissions.includes(workspace_roles[i].role)
      ) {
        return true;
      }
    }
    return false;
  };
  const deny = (
    permissions: string[],
    user_id: number,
    workspace_roles: WorkspaceRole[],
  ) => {
    return !grant(permissions, user_id, workspace_roles);
  };

  // invitations

  const accept = async (workspace_: Workspace) => {
    return new Promise(function (resolve, reject) {
      api
        .put("workspace/invite/accept", {
          workspace_id: workspace_.id,
        })
        .then(() => {
          for (let i = 0; i < workspace_.roles.length; ++i) {
            if (workspace_.roles[i].user.id === authStore.user!.id) {
              workspace_.roles[i].accepted = true;
            }
          }
          resolve(undefined);
        })
        .catch((e: unknown) => {
          reject(e);
        });
    });
  };
  const reject = (workspace_: Workspace) => {
    return new Promise(function (resolve, reject) {
      api
        .put("workspace/invite/reject", {
          workspace_id: workspace_.id,
        })
        .then(() => {
          workspaces.value = workspaces.value!.filter(
            (workspace__) => workspace__.id !== workspace_.id,
          );
          resolve(undefined);
        })
        .catch((e: unknown) => {
          reject(e);
        });
    });
  };

  const acceptedCount = computed<number | undefined>(() => {
    if (!workspaces.value || !authStore.user) {
      return undefined;
    }
    let count = 0;
    if (workspaces.value && authStore.user) {
      workspaces.value.forEach((workspace_) => {
        for (let ii = 0; ii < workspace_.roles.length; ++ii) {
          if (
            workspace_.roles[ii].user.id === authStore.user?.id &&
            workspace_.roles[ii].accepted
          ) {
            count++;
            return;
          }
        }
      });
    }
    return count;
  });
  const invitationCount = computed<number | undefined>(() => {
    if (!workspaces.value || !authStore.user) {
      return undefined;
    }
    return workspaces.value.length - acceptedCount.value!;
  });
  const hasInvitations = computed<boolean | undefined>(() => {
    if (invitationCount.value === undefined) {
      return undefined;
    }
    return invitationCount.value >= 1;
  });

  const isAccepted = (workspace_: Workspace): boolean => {
    for (let i = 0; i < workspace_.roles.length; ++i) {
      if (
        workspace_.roles[i].user.id === authStore.user?.id &&
        workspace_.roles[i].accepted
      ) {
        return true;
      }
    }
    return false;
  };
  const isPending = (workspace_: Workspace): boolean => {
    let hasRole: boolean = false;
    for (let i = 0; i < workspace_.roles.length; ++i) {
      if (workspace_.roles[i].user.id === authStore.user?.id) {
        hasRole = true;
        if (workspace_.roles[i].accepted) {
          return false;
        }
      }
    }
    return hasRole;
  };

  // number badges

  type WorkspaceNotifications = {
    workspace_id: number;
    total: number;
  };

  const workspacesNotifications = computed<
    WorkspaceNotifications[] | undefined
  >(() => {
    if (!workspaces.value) {
      return undefined;
    }
    const collection: WorkspaceNotifications[] = [];
    for (let i = 0; i < workspaces.value.length; ++i) {
      collection.push({
        workspace_id: workspaces.value[i].id,
        total: 0,
      });
    }
    return collection;
  });

  const notificationsForWorkspace = (
    workspace_id: number,
  ): WorkspaceNotifications | undefined => {
    if (!workspacesNotifications.value) {
      return undefined;
    }
    for (let i = 0; i < workspacesNotifications.value.length; ++i) {
      if (workspacesNotifications.value[i].workspace_id === workspace_id) {
        return workspacesNotifications.value[i];
      }
    }
    return undefined;
  };
  const workspaceNotifications = computed<WorkspaceNotifications | undefined>(
    () => {
      if (!workspace.value) {
        return undefined;
      }
      return notificationsForWorkspace(workspace.value.id);
    },
  );

  // recent route

  const recentRoute = ref<SerializableRoute | undefined>(
    Capacitor.isNativePlatform()
      ? undefined
      : sessionStorage.getItem("recent-route")
        ? (JSON.parse(
            sessionStorage.getItem("recent-route") as string,
          ) as SerializableRoute)
        : undefined,
  );

  const setRecentRoute = (route: RouteLocationNormalized) => {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    const raw = {
      name: route.name,
      params: route.params,
      query: route.query,
      hash: route.hash,
      meta: route.meta,
      matched: route.matched.map((item) => {
        return {
          name: item.name,
          meta: item.meta,
        } as SerializableMatch;
      }),
    } as SerializableRoute;
    recentRoute.value = raw;
    sessionStorage.setItem("recent-route", JSON.stringify(raw));
  };
  const clearRecentRoute = () => {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    recentRoute.value = undefined;
    sessionStorage.removeItem("recent-route");
  };

  const accountRoute = computed(() => {
    if (!accountStore.accounts || !accountStore.accounts.length) {
      return undefined;
    }
    if (accountStore.accounts.length === 1) {
      return {
        name: "account",
        params: {
          account: accountStore.accounts[0].id,
        },
      };
    }
    if (workspace.value) {
      for (let i = 0; i < accountStore.accounts.length; ++i) {
        if (accountStore.accounts[i].id === workspace.value.account.id) {
          return {
            name: "account",
            params: {
              account: accountStore.accounts[i].id,
            },
          };
        }
      }
    }
    for (let i = 0; i < accountStore.accounts.length; ++i) {
      for (let ii = 0; ii < accountStore.accounts[i].roles.length; ++ii) {
        if (accountStore.accounts[i].roles[ii].role === AccountRoles.OWNER) {
          return {
            name: "account",
            params: {
              account: accountStore.accounts[i].id,
            },
          };
        }
      }
    }
    return {
      name: "account",
      params: {
        account: accountStore.accounts[0].id,
      },
    };
  });

  const showInvitations = computed<boolean>(() => {
    return (
      invitationCount.value! >= 1 &&
      !["workspaces", "invites"].includes(route.matched[0].name! as string)
    );
  });
  const menuNotificationCount = computed<number>(() => {
    let count = 0;
    if (showInvitations.value) {
      count += invitationCount.value ?? 0;
    }
    return count;
  });
  const reload = async (workspace: Workspace) => {
    const data: unknown = await api.get(
      `workspace/reload?workspace_id=${workspace.id}`,
    );
    mergeWorkspaceRaw(data as WorkspaceRaw);
  };
  const campaigns_timeframe = ref<CampaignsTimeframe | undefined>(
    window.campaigns_timeframe?.length
      ? (window.campaigns_timeframe as CampaignsTimeframe)
      : CampaignsTimeframe.PAST_YEAR,
  );
  delete window.campaigns_timeframe;

  watch(campaigns_timeframe, () => {
    if (campaigns_timeframe.value) {
      Preferences.set({
        key: "campaigns_timeframe",
        value: `${campaigns_timeframe.value}`,
      }).then(() => {});
    } else {
      Preferences.remove({ key: "campaigns_timeframe" }).then(() => {});
    }
  });

  const setCampaignsTimeframe = (campaigns_timeframe_: CampaignsTimeframe) => {
    campaigns_timeframe.value = campaigns_timeframe_;
  };

  const find = (workspace_id: number): Workspace | undefined => {
    return workspaces.value!.find(
      (workspace: Workspace) => workspace.id === workspace_id,
    );
  };

  const groupedCampaigns = computed<GroupedCampaigns[] | undefined>(() => {
    if (!workspace.value) {
      return undefined;
    }
    const items: GroupedCampaigns[] = [];
    const campaign_ids_accounted_for: number[] = [];

    for (let i = 0; i < workspace.value.shared_budgets.length; i++) {
      const shared_budget_campaigns: Campaign[] = [];
      for (let ii = 0; ii < workspace.value.campaigns.length; ii++) {
        if (
          workspace.value.campaigns[ii].shared_budget_id ===
          workspace.value.shared_budgets[i].id
        ) {
          shared_budget_campaigns.push(workspace.value.campaigns[ii]);
          campaign_ids_accounted_for.push(workspace.value.campaigns[ii].id);
        }
      }
      items.push({
        key: `${workspace.value.shared_budgets[i].id}`,
        group: workspace.value.shared_budgets[i],
        campaigns: shared_budget_campaigns,
      } as GroupedCampaigns);
    }
    if (campaign_ids_accounted_for.length < workspace.value.campaigns.length) {
      const shared_budget_campaigns: Campaign[] = [];
      for (let i = 0; i < workspace.value.campaigns.length; i++) {
        if (
          !campaign_ids_accounted_for.includes(workspace.value.campaigns[i].id)
        ) {
          shared_budget_campaigns.push(workspace.value.campaigns[i]);
        }
      }
      if (shared_budget_campaigns.length) {
        items.push({
          key: `ungrouped`,
          group: undefined,
          campaigns: shared_budget_campaigns,
        } as GroupedCampaigns);
      }
    }
    return items;
  });

  return {
    find,
    campaigns_timeframe,
    setCampaignsTimeframe,

    workspaces,
    workspacesLoaded,
    workspacesInAccounts,
    acceptedWorkspacesInAccounts,
    pendingWorkspacesInAccounts,
    latestWorkspace,
    setWorkspaces,
    clearWorkspaces,
    // insertWorkspace,
    workspace,
    mergeWorkspace,
    mergeWorkspaceRaw,
    workspaceChanged,
    workspaceById,
    reload,
    // createWorkspace,

    convertRawWorkspace,
    convertRawWorkspaceRole,

    accountRoute,
    showInvitations,
    menuNotificationCount,

    // shared_budgets
    mergeSharedBudget,
    mergeRawSharedBudget,
    mergeRawSharedBudgets,
    removeSharedBudget,

    // campaigns
    mergeCampaign,
    mergeRawCampaign,
    mergeRawCampaigns,
    removeCampaign,

    // ad_channels
    mergeAdChannel,
    mergeAdChannels,
    removeAdChannel,

    // permissions
    grant,
    deny,

    // invitations
    accept,
    reject,
    acceptedCount,
    invitationCount,
    hasInvitations,
    isAccepted,
    isPending,

    // number badges
    notificationsForWorkspace,
    workspaceNotifications,

    // recent route
    recentRoute,
    setRecentRoute,
    clearRecentRoute,

    groupedCampaigns,
  };
});

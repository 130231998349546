<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";

import { contents, Item, selectedItem } from "@/app/docs/contents";
import create_account from "@/assets/docs/create-account.png";

const route = useRoute();

const page = computed<Item | undefined>(() => selectedItem(route, contents));
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="create_account" />
    </div>
    <p>
      To create an account, simply click “sign up” on Charter’s website. You can
      either sign up using your Meta or Google account, or via email. If via
      email, you’ll receive a one-time-passcode to your email inbox to login
      instead of a password.
    </p>

    <p>
      The email/Meta/Google you use to sign up is your user, you can have
      multiple accounts under the same user.
    </p>

    <p>
      When you make an account, you are automatically made Admin of the account
      and responsible for billing, team management etc. To learn more about
      account management click here.
    </p>
  </div>
</template>

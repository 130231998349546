<script setup lang="ts">
import { Cross2Icon } from "@radix-icons/vue";
import { PropType } from "vue";

const emit = defineEmits<{
  (e: "close"): void;
}>();
const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  titleBorder: {
    type: Boolean,
    required: false,
    default: false,
  },
  component: {
    type: Object,
    required: false,
    default: undefined,
  },
});
</script>
<template>
  <div
    v-if="props.title"
    :class="`relative flex items-center justify-between border-0 border-white/10 py-2.5 pl-4 text-lg font-semibold sm:pl-6 ${props.titleBorder ? `min-h-[60px] border-b-[1px]` : `min-h-[61px]`}`"
  >
    <span class="relative -top-[1px] leading-tight">{{ props.title }}</span>
    <div class="flex flex-none items-center">
      <div class="relative -top-[1px]">
        <slot name="right"></slot>
      </div>
      <div
        class="group mx-3 flex h-[40px] w-[40px] cursor-pointer items-center justify-center"
        @click="emit('close')"
      >
        <Cross2Icon
          class="h-6 w-6 opacity-30 transition-all duration-75 group-hover:opacity-100"
        ></Cross2Icon>
      </div>
    </div>
  </div>
</template>
